import { Box, FormLabel } from '@mui/material';
import { LanguageCodeType, fileUseYn } from '@src/common/config/Code';
import { useGetLanguageSpecList } from '@src/hooks/task/getLanguageSpec';
import { usePageFunc } from '@src/hooks/utils';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { TPInputDate } from '@src/units/input/TPInput.date';
import { TPInputRadio } from '@src/units/input/TPInput.radio';
import { TPSelectBasic } from '@units/select/TPSelect.basic';
import { differenceInMinutes } from 'date-fns';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import AntSwitch from '../components/AntSwitch';
import { TaskAmountInputs } from '../components/TaskAmountInputs.Comp';
import { cssTaskMaker } from './JobRegister.style';

export interface onMakeProps {
  isBoracatUseOrNot: boolean;
  fileUse: string;
  tmMatch: boolean;
  taskAmount: { [key: string]: any };
  totalAmount: { totalGrossAmount: number; totalNetAmount: number };
  startLanguage: LanguageCodeType;
  destinationLanguages: LanguageCodeType[];
  endDateTime: Date | undefined;
  hopeSupplyDateTime: Date | undefined;
  languageTaskId: number | undefined;
}

export interface LanguageTaskMakerProps {
  openCalendar: (type: string, date: Date | undefined, setState: any) => void;
  startDateTime: Date | undefined;
  onMake: (v: onMakeProps) => void;
}

export function LanguageTaskMaker({
  openCalendar,
  startDateTime,
  taskType,
  onMake
}: any) {
  const { languageSpecList } = useGetLanguageSpecList(); // 서버에서 불러온 언어 목록
  const [hopeSupplyDateTime, setHopeSupplyDateTime] = useState<
    Date | undefined
  >(undefined);
  const [fileUse, setFileUse] = useState<string>('out'); // 보라캣 사용여부
  const isBoracatUseOrNot = useMemo(() => fileUse === 'in', [fileUse]);

  const [tmMatch, setTmMatch] = useState<boolean>(false);
  const [taskAmount, setTaskAmount] = useState<{ [key: string]: any }>({
    percent101: '0',
    percentRep: '0',
    percent100: '0',
    percent9599: '0',
    percent8594: '0',
    percent7584: '0',
    percent5074: '0',
    noMatch: '0'
  });
  const [totalAmount, setTotalAmount] = useState({
    totalGrossAmount: 0,
    totalNetAmount: 0
  });
  const { taskAmountRate, taskAmountText, getDateTime } = usePageFunc();
  const [endDateTime, setEndDateTime] = useState<Date | undefined>(undefined);
  const [hour, setHour] = useState<number>(0);
  const [minute, setMinute] = useState<string>('0');
  const [startLanguage, setStartLanguage] = useState<LanguageCodeType>('KO');
  const [destinationLanguages, setDestinationLanguages] = useState<
    LanguageCodeType[]
  >([]);

  const changeDestinationLanguages = (value: any) => {
    setDestinationLanguages((old) => {
      const s = new Set(old);
      if (s.has(value)) s.delete(value);
      else s.add(value);
      return Array.from(s);
    });
  };

  const changeInput = (data: { [key: string]: any }) => {
    setTaskAmount(data);
    const totalNetAmount = Number(
      Object.values(taskAmountRate)
        .reduce((sum, val, index) => {
          return sum + Number(Object.values(data)[index]) * val;
        }, 0)
        .toFixed()
    );
    const totalGrossAmount = Number(
      Object.values(data).reduce((sum, val, index) => {
        return sum + Number(Object.values(data)[index]);
      }, 0)
    );
    setTotalAmount({ totalGrossAmount, totalNetAmount });
  };

  const changeTimeInput = (time: string, type: 'hour' | 'minute') => {
    const amount =
      type === 'hour'
        ? Number(Number(time) * 250 + Number(minute) * 250)
        : Number(hour * 250 + Number(time) * 250);
    setTotalAmount({ totalGrossAmount: amount, totalNetAmount: amount });
  };

  const endDateTimeMessage = () => {
    let msg = '';
    if (endDateTime && startDateTime) {
      if (differenceInMinutes(startDateTime, endDateTime) > 0) {
        msg = '마감일이 시작일보다 빠릅니다.';
      }
    }
    return msg;
  };

  const hopeSupplyDateTimeMessage = () => {
    let msg = '';

    if (hopeSupplyDateTime && endDateTime && startDateTime) {
      if (differenceInMinutes(startDateTime, hopeSupplyDateTime) > 0) {
        msg = '납품일이 시작일보다 빠릅니다.';
      } else if (differenceInMinutes(endDateTime, hopeSupplyDateTime) > 0) {
        msg = '납품일이 마감일보다 빠릅니다.';
      }
    }
    return msg;
  };

  const isOnMakeDisabled = useMemo(() => {
    const a =
      isBoracatUseOrNot ||
      totalAmount.totalGrossAmount > 0 ||
      totalAmount.totalNetAmount > 0;
    const b = Boolean(
      startDateTime &&
        endDateTime &&
        hopeSupplyDateTime &&
        endDateTimeMessage().length === 0 &&
        hopeSupplyDateTimeMessage().length === 0
    );
    const c = startLanguage.length > 0 && destinationLanguages.length > 0;
    return !(a && b && c);
  }, [
    isBoracatUseOrNot,
    totalAmount.totalGrossAmount,
    totalAmount.totalNetAmount,
    startDateTime,
    endDateTime,
    hopeSupplyDateTime,
    startLanguage,
    destinationLanguages
  ]);

  useEffect(() => {
    if (!tmMatch && taskType !== 'LQA') {
      const getData = {
        ...taskAmount,
        percent101: '0',
        percent100: '0',
        percent9599: '0',
        percent8594: '0',
        percent7584: '0',
        percent5074: '0'
      };
      setTaskAmount(getData);

      const totalNetAmount = Number(
        Object.values(taskAmountRate)
          .reduce((sum, val, index) => {
            return sum + Number(Object.values(getData)[index]) * val;
          }, 0)
          .toFixed()
      );
      const totalGrossAmount = Number(
        Object.values(getData).reduce((sum, val, index) => {
          return sum + Number(Object.values(getData)[index]);
        }, 0)
      );

      setTotalAmount({ totalGrossAmount, totalNetAmount });
    }
  }, [tmMatch]);

  return (
    <div css={cssTaskMaker}>
      <div>
        <TPInputRadio
          value={fileUse}
          label={'작업 환경'}
          required={true}
          name={'fileUse'}
          options={fileUseYn}
          changeRadio={(e: any) => setFileUse(e.target.value)}
        />
      </div>
      <div className="totalRate">
        <div className="totalRateTitleRow">
          <div className="left">
            {isBoracatUseOrNot ? (
              <>
                <div className="title">
                  <div>
                    총 분량 <span className="color--red">*</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="title">
                  <div>
                    총 분량 <span className="color--red">*</span>
                  </div>
                </div>
              </>
            )}
            {taskType !== 'LQA' && !isBoracatUseOrNot && (
              <div className="grossAndNet">
                <div className="gross">
                  <span className="name">Gross</span>
                  <span className="value">
                    {totalAmount.totalGrossAmount.toLocaleString('en')}
                    {/* 입력한 매치율 별 단어 수 총합이 자동 계산됩니다. */}
                  </span>
                </div>
                <div className="title">
                  <span className="name">Net</span>
                  <span className="value">
                    {totalAmount.totalNetAmount.toLocaleString('en')}
                    {/* 입력한 매치율 별 단어 수가 각 배율에 따라 자동 계산됩니다. */}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="controls">
            {taskType === 'LQA' ? (
              <>(250단어 · 자 / 시간)</>
            ) : (
              !isBoracatUseOrNot && (
                <>
                  TM매치
                  <AntSwitch
                    checked={tmMatch}
                    onChange={() => setTmMatch((v) => !v)}
                    inputProps={{ 'aria-label': 'ant design' }}
                  />
                </>
              )
            )}
          </div>
        </div>
        {taskType !== 'LQA' && isBoracatUseOrNot && (
          <div className="desc">
            작업 파일 등록 시, 분량이 자동 계산 됩니다.
          </div>
        )}
        {taskType === 'LQA' ? (
          <div className="time-calculation">
            <input
              value={hour}
              type="number"
              className="hour"
              onChange={(e) => {
                if (Number(e.target.value) < 0) {
                  return;
                }
                setHour(Number(e.target.value));
                changeTimeInput(e.target.value, 'hour');
              }}
            />
            시간
            <TPSelectBasic
              large={true}
              value={minute}
              options={[
                {
                  label: '0',
                  option: '0'
                },
                {
                  label: '30',
                  option: '0.5'
                }
              ]}
              autoWidth={false}
              color={'#727272'}
              changeSelect={(e) => {
                setMinute(e.target.value);
                changeTimeInput(e.target.value, 'minute');
              }}
            />
            분
          </div>
        ) : (
          !isBoracatUseOrNot && (
            <TaskAmountInputs
              tmMatch={tmMatch}
              taskAmount={taskAmount}
              inputText={taskAmountText}
              changeInput={changeInput}
            />
          )
        )}
      </div>
      <div className="dates">
        <div>
          <TPInputDate
            name={'endDateTime'}
            label={'마감일'}
            date={endDateTime}
            helperText={endDateTimeMessage()}
            placeholder={'날짜를 입력해 주세요.'}
            openHandle={() => openCalendar('end', endDateTime, setEndDateTime)}
            required
          />
        </div>
        <div>
          <TPInputDate
            name="hopeSupplyDateTime"
            label="납품일"
            placeholder="날짜를 입력해 주세요."
            date={hopeSupplyDateTime}
            openHandle={() =>
              openCalendar('hope', hopeSupplyDateTime, setHopeSupplyDateTime)
            }
            helperText={hopeSupplyDateTimeMessage()}
            required
          />
        </div>
      </div>

      <div className="workingLanguage">
        <Box display={'flex'} textAlign={'left'}>
          <FormLabel
            htmlFor={'작업언어'}
            sx={{ fontFamily: 'Spoqa Han Sans Neo,sans-serif' }}
          >
            작업언어
            <Box component={'sup'} color="#E6311A">
              *
            </Box>
          </FormLabel>
        </Box>
        <Box display={'grid'} gridTemplateColumns={'1fr 1fr 82px'} gap={'12px'}>
          <TPSelectBasic
            large={true}
            value={startLanguage}
            label={'출발어'}
            options={_.map(languageSpecList?.startLanguageList, (el) => ({
              label: el.key,
              option: el.value
            }))}
            autoWidth={false}
            changeSelect={(e) => {
              setStartLanguage(e.target.value);
            }}
            // isDisabled={isDisabled}
          />
          <TPSelectBasic
            large={true}
            multiple={true}
            value={destinationLanguages}
            label={'도착어'}
            placeholder={'선택'}
            options={_.map(
              _.filter(
                languageSpecList?.destinationLanguageList ?? [],
                (v) => v.value !== startLanguage
              ),
              (el) => ({ label: el.key, option: el.value })
            )}
            // @ts-ignore
            changeSelect={(e, { props: { value } }) => {
              changeDestinationLanguages(value);
            }}
            autoWidth={false}
            isDisabled={false}
          />
          <TPButtonBasic
            variant="contained"
            label="추가"
            fullWidth
            disableElevation
            disabled={isOnMakeDisabled}
            onClick={() => {
              onMake({
                isBoracatUseOrNot,
                fileUse,
                tmMatch,
                taskAmount,
                totalAmount,
                startLanguage,
                destinationLanguages,
                endDateTime,
                hopeSupplyDateTime,
                languageTaskId: undefined
              });
              setFileUse('out');
              setEndDateTime(undefined);
              setHopeSupplyDateTime(undefined);
              setStartLanguage('KO');
              setDestinationLanguages([]);
              setTotalAmount({ totalGrossAmount: 0, totalNetAmount: 0 });
              setTaskAmount({
                percent101: '0',
                percentRep: '0',
                percent100: '0',
                percent9599: '0',
                percent8594: '0',
                percent7584: '0',
                percent5074: '0',
                noMatch: '0'
              });
            }}
          />
        </Box>
      </div>
    </div>
  );
}
