import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@hooks/modal/useToast';
import { useAlert } from '../modal/useAlert';
import { projectDetailKeys, taskKeys } from '../queryKeyFactory';

export const useDeleteLanguageSubTask = () => {
  const queryClient = useQueryClient();
  const { openHandle: toastOpen } = useToast();
  const { openHandle: alertHandle } = useAlert();

  const deleteStatus = async (id: string | number) => {
    return await api.delete(`/languageTask/${id}`);
  };

  const { mutate: deleteLanguageTask } = useMutation({
    mutationFn: deleteStatus,
    onSuccess: (variables) => {
      queryClient.invalidateQueries(['DashboardKeys']);
      queryClient.invalidateQueries(['/task']);
      queryClient.invalidateQueries([...projectDetailKeys.default]);
      toastOpen({ text: '언어 작업이 삭제 되었습니다.', severity: 'success' });
    },
    onError: (error: any) => {
      toastOpen({ text: '언어 작업 삭제에 실패했습니다.', severity: 'error' });
    }
  });

  const deleteTaskAlert = ({ id }: { id: string | number }) => {
    alertHandle({
      title: '언어 작업 삭제',
      text: '한 번 삭제된 데이터는 복구가 불가능합니다.',
      cancelLabel: '취소',
      ok: () => {
        deleteLanguageTask(id);
      }
    });
  };

  return { deleteLanguageTask, deleteTaskAlert };
};
