import { Fragment } from 'react';
import { useApi1CategoryList } from '../hooks/useApi1CategoryList';
import { Row1CategoryItem } from './Row1CategoryItem';
import Loading from '@src/pages/components/Loading';
import styled from '@emotion/styled';

export function Row1CategoryList({
  isFilter,
  filterData,
  setOpenedJobModifyPage,
  setOpenedJobRegisterPage,
  setOpenedJobDetailPage,
  listFilter,
  sortState,
  onSort
}: any) {
  const { data, error, isError, isSuccess } = useApi1CategoryList(
    filterData,
    isFilter
  );

  return (
    <div className="row-1-category-list">
      {isError && <div>{(error as any)?.message}</div>}
      {isSuccess &&
        Array.isArray(data) &&
        data.map((item, i) => (
          // <Fragment key={i}>
          <Row1CategoryItem
            key={i}
            {...{
              filterData,
              item,
              setOpenedJobModifyPage,
              setOpenedJobRegisterPage,
              setOpenedJobDetailPage,
              listFilter,
              sortState,
              onSort
            }}
          />
          // </Fragment>
        ))}
    </div>
  );
}
