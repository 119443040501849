import { cx } from '@emotion/css';
import { ThemeProvider } from '@emotion/react';

import { Box, ButtonBase, createTheme, styled } from '@mui/material';
import { ReactNode, useState } from 'react';

const MyButton = styled(ButtonBase)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: 500,
  color: '#2c3558',
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderColor: 'transparent'
  }
}));

const colors = {
  normalArrow: '#AAAAAA',
  hoverArrow: '#242424',
  selectedArrow: '#9B51E0',
  normalCloseButton: '#727272',
  normalCloseButtonBg: '#ffffff',
  hoverCloseButton: '#ffffff',
  hoverCloseButtonBg: '#000000'
};

type StatusProps = {
  sortType: 'up' | 'down';
  close?: () => void;
};

const Status = function (props: StatusProps) {
  const upArrowColor =
    props.sortType === 'up' ? colors.selectedArrow : colors.normalArrow;
  const downArrowColor =
    props.sortType === 'down' ? colors.selectedArrow : colors.normalArrow;
  return (
    <Box
      onClick={() => {
        const fn = props.close;
        if (typeof fn === 'function') {
          fn();
        }
      }}
      sx={{
        svg: {
          width: '62px !important',
          height: '24px !important',
          '.close-btn-circle': {
            fill: colors.normalCloseButtonBg,
            stroke: colors.normalCloseButtonBg
          },
          '.close-btn-x': {
            fill: colors.normalCloseButton
          }
        },
        '&:hover svg': {
          '.close-btn-circle': {
            fill: colors.hoverCloseButtonBg,
            stroke: colors.hoverCloseButtonBg
          },
          '.close-btn-x': {
            fill: colors.hoverCloseButton,
            stroke: colors.hoverCloseButton
          }
        }
      }}
    >
      <svg
        width="62"
        height="24"
        viewBox="0 0 62 24"
        xmlns="http://www.w3.org/2000/svg"
        // transform="translate(0, 21)"
      >
        <rect
          x="0.7"
          y="1.7"
          width="32.6"
          height="16.6"
          rx="8.3"
          fill="white"
          stroke="#ECECEC"
          strokeWidth="1.4"
        />
        <path
          d="M10.5 8.70711L7.20711 12H13.7929L10.5 8.70711Z"
          fill={upArrowColor}
          stroke={upArrowColor}
        />
        <path
          d="M23.5 11.7929L20.2071 8.5H26.7929L23.5 11.7929Z"
          fill={downArrowColor}
          stroke={downArrowColor}
        />
        <g filter="url(#filter0_d_535_3156)">
          <circle className="close-btn-circle" cx="48" cy="10" r="8" />
        </g>
        <path
          className="close-btn-x"
          d="M50.8584 6.3584L48.136 9.08076L45.4137 6.3584L45.3076 6.25233L45.2015 6.3584L44.4944 7.06551L44.3884 7.17157L44.4944 7.27764L47.2168 10L44.5911 12.6257L44.485 12.7318L44.5911 12.8378L45.2982 13.5449L45.4043 13.651L45.5104 13.5449L48.136 10.9192L50.8584 13.6416L50.9645 13.7477L51.0705 13.6416L51.7776 12.9345L51.8837 12.8284L51.7776 12.7224L49.0553 10L51.7776 7.27764L51.8837 7.17157L51.7776 7.06551L51.0705 6.3584L50.9645 6.25233L50.8584 6.3584Z"
          strokeWidth="0.3"
        />
        <defs>
          <filter
            id="filter0_d_535_3156"
            x="38"
            y="0"
            width="24"
            height="24"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_535_3156"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_535_3156"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Box>
  );
};

export const IconStatusNormal = () => {
  return (
    <svg
      width="34"
      height="18"
      viewBox="0 0 34 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.7"
        y="0.7"
        width="32.6"
        height="16.6"
        rx="8.3"
        fill="white"
        stroke="#ECECEC"
        strokeWidth="1.4"
      />
      <path
        d="M10.5 7.70711L7.20711 11H13.7929L10.5 7.70711Z"
        fill="#AAAAAA"
        stroke="#AAAAAA"
      />
      <path
        d="M23.5 10.7929L20.2071 7.5H26.7929L23.5 10.7929Z"
        fill="#AAAAAA"
        stroke="#AAAAAA"
      />
    </svg>
  );
};

export type SortButtonProps = {
  onChange?: (v: any) => void;
  show?: boolean;
  children: ReactNode;
  css?: any;
  sortType?: 'up' | 'down';
};
export const SortButton = function (props: SortButtonProps) {
  const [internalSortType, setInternalSortType] = useState<
    'up' | 'down' | undefined
  >(undefined);
  const currentSortType =
    props.sortType !== undefined ? props.sortType : internalSortType;
  const isActived =
    typeof props.show === 'boolean' ? props.show : Boolean(currentSortType);

  const handleToogleSortType = () => {
    const newType = currentSortType === 'up' ? 'down' : 'up';
    if (props.sortType === undefined) {
      setInternalSortType(newType);
    }
    props.onChange?.(newType);
  };
  const handleCancelSortType = () => {
    if (props.sortType === undefined) {
      setInternalSortType(undefined);
    }
    props.onChange?.(undefined);
  };

  return (
    <ThemeProvider
      theme={createTheme({
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontFamily: ['"IBM Plex Sans"'].join(','),
                margin: 0,
                padding: 0,
                backgroundColor: 'transparent'
              }
            }
          },
          MuiGrid: {
            styleOverrides: {
              root: {
                fontFamily: ['"IBM Plex Sans"'].join(','),
                margin: 0,
                padding: 0
              },
              item: {
                border: '1.4px solid #ECECEC'
              }
            }
          }
        }
      })}
    >
      <Box
        position="relative"
        sx={{
          '.icon-status-normal': {
            display: 'none'
          },
          '&:hover .icon-status-normal': {
            display: isActived ? 'none' : 'block'
          }
        }}
      >
        <Box>
          <MyButton
            disableRipple
            disableTouchRipple
            onClick={() => {
              handleToogleSortType();
            }}
            className={cx(props.css)}
            sx={{
              width: '100%',
              zIndex: 1
            }}
          >
            {props.children}
          </MyButton>
        </Box>
        <Box
          className="icon-status"
          sx={{
            display: isActived ? 'block' : 'none',
            position: 'absolute',
            top: '0%',
            left: '50%',
            transform: 'translate(-50%, -70%)'
          }}
        >
          <Status
            sortType={currentSortType as any}
            close={handleCancelSortType}
          />
        </Box>
        <Box
          className="icon-status-normal"
          sx={{
            position: 'absolute',
            top: '0%',
            left: '50%',
            transform: 'translate(-50%, -20px)',
            svg: {
              width: '34px !important',
              height: '18px !important'
            }
          }}
        >
          <IconStatusNormal />
        </Box>
      </Box>
    </ThemeProvider>
  );
};
