import React from 'react';
import { Box, styled } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { TPAvatarBasic } from '@units/avatar/TPAvatar.Basic';
import { IconDefaultProfileUrl } from '@src/resources/icon';

export interface AvatarProps {
  worker: any;
  size?: number;
  type?: string;
}

export function Avatar({ worker, size = 30, type }: AvatarProps) {
  return (
    <div className="avatar">
      {worker?.avatarImgUrl ? (
        <Tooltip
          title={worker.name}
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: '12px',
                color: 'black',
                backgroundColor: 'white',
                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)'
              }
            },
            popper: {
              sx: {
                [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                  {
                    marginTop: '4px'
                  },
                [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                  {
                    marginBottom: '0px'
                  },
                [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                  {
                    marginLeft: '0px'
                  },
                [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                  {
                    marginRight: '0px'
                  }
              }
            }
          }}
        >
          <TPAvatarBasic
            src={worker.avatarImgUrl}
            sx={{
              width: size,
              height: size
            }}
            grayscale={
              worker.status === 'INACTIVE' || worker.status === 'LEAVE'
            }
          />
        </Tooltip>
      ) : (
        <AvatarDefault>
          {!type && <img src={IconDefaultProfileUrl} alt="기본 프로필" />}
        </AvatarDefault>
      )}
    </div>
  );
}

const AvatarDefault = styled(Box)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ececec;
`;
