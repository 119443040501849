import Api from '@src/api/Api';
import { useQuery } from '@tanstack/react-query';
import { DashboardKeys } from '../keys/DashboardKeys';
import { useMemo } from 'react';

export function useApi2TaskList(label: string, filterData: any) {
  const { data: initialData, ...rest } = useQuery(
    DashboardKeys.Deep2(label, filterData),
    () =>
      Api.post('/task/listByDay', {
        ...filterData,
        label
      }).then((res) => {
        const v = res.data?.taskList as any[];
        if (!Array.isArray(v)) return [];
        return v;
      }),
    {
      enabled: true,
      retry: 3,
      suspense: true,
      useErrorBoundary: true
    }
  );

  // 데이터를 불러온 후 정렬 수행
  const sortedData = useMemo(() => {
    if (Array.isArray(initialData) && initialData.length > 0) {
      if (initialData[0]['endDateTime']) {
        return [...initialData].sort((a: any, b: any) => {
          return a.endDateTime < b.endDateTime
            ? -1
            : a.endDateTime > b.endDateTime
            ? 1
            : 0;
        });
      }
    }
    return initialData;
  }, [initialData]);

  return { ...rest, data: sortedData };
}
