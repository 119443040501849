import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@hooks/modal/useToast';
import { useAlert } from '../modal/useAlert';

export const useDeleteSubTask = () => {
  const queryClient = useQueryClient();
  const { openHandle: toastOpen } = useToast();
  const { openHandle: alertHandle } = useAlert();

  const deleteStatus = async (id: string | number) => {
    return await api.delete(`/languageSubTask/${id}`);
  };

  const { mutate: deleteSubTask } = useMutation({
    mutationFn: deleteStatus,
    onSuccess: (variables) => {
      queryClient.invalidateQueries(['DashboardKeys']);
      queryClient.invalidateQueries(['/task']);
      toastOpen({ text: '부작업이 삭제되었습니다.', severity: 'success' });
    },
    onError: (error: any) => {
      toastOpen({ text: '부작업 삭제에 실패했습니다.', severity: 'error' });
    }
  });

  const deleteSubTaskAlert = ({
    id
  }: {
    id: string | number;
  }): Promise<boolean> => {
    return new Promise((resolve) => {
      alertHandle({
        title: '언어 작업 삭제',
        text: '한 번 삭제된 데이터는 복구가 불가능합니다.',
        cancelLabel: '취소',
        ok: () => {
          deleteSubTask(id);
          resolve(true);
        },
        cancel: () => {
          resolve(false);
        }
      });
    });
  };

  return { deleteSubTask, deleteSubTaskAlert };
};
