//lib
import {
  useEffect,
  useInsertionEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Backdrop, Box } from '@mui/material';
import { format } from 'date-fns';
//etc
import { IconCalendar, IconRefresh } from '@resources/icon';
import { MainWrap } from './Main.style';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import { TPSelectCheckManager } from '@src/units/select/TPSelect.checkPM';
import { TPSelectOne } from '@src/units/select/TPSelect.one';
import { TPButtonActionCalendar } from '@src/units/button/TPButton.ActionCalendar';
import { getStatus, getDateType } from '@src/common/config/Code';
import { mainColumns } from './Main.columns';
import DataTable from '../components/DataTable/DataTable';
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
import { DashBoard } from '../components/DashBoard';
import UnderlineTitle from '../components/UnderlineTitle';
//hooks
import { useGetProjectList } from '@src/hooks/project/postList';
import { useGetPmList } from '@src/hooks/project/getPmList';
import { useGetFilter } from '@src/hooks/searchFilter';
import { useRangeCalendar } from '@hooks/modal/useRangeCalendar';
import { useMyInfo } from '@hooks/myInfo/getMyInfo';
import { SubTitleWrap, TitleWrap } from '../dashboard/utils/render';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { useSetGlobalState } from '@src/hooks/useGlobalState';
import { useGetTaskManagerList } from '@src/hooks/task/getManagerList';
import { initialDataMap } from '@src/hooks/projectDetail/postList';
import Footer from '@src/layouts/footer/Footer';
import {
  useGetAllProjectAssignerList,
  useGetProjectAssignerList
} from '@src/hooks/project/getAssignList';
import { createPortal } from 'react-dom';
import MainTable from './MainTable';
import TPButtonReset from '@src/units/button/TPButton.Reset';

/**
 * 프로젝트 페이지
 * route : /project
 * auth  : PML, PM
 * @returns
 */
function MainPage() {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  //#region hooks call
  const { myInfo, isLoading } = useMyInfo();
  const { filterData, Component: FilterButton } = useGetFilter('PROJECT');
  const { pmList } = useGetPmList();
  const { projectAssignerList } = useGetProjectAssignerList();
  const { allProjectAssignerList } = useGetAllProjectAssignerList();
  const [searchValue, setSearchValue] = useState('');
  const [reset, setReset] = useState(false);
  const {
    projectTitle, // searchValue
    projectList,
    isLoading: isLoadingProject,
    refetchProjectList,
    counter,
    dashBoardItemList,
    projectStatus,
    dateType,
    projectAssignerUserIdList,
    projectManagerUserIdList,
    dateFilter,
    isFilter,
    setIsFilter,
    isSort,
    setState
  } = useGetProjectList(filterData);
  const {
    openHandle,
    Component: Calendar,
    open: calendarOpened
  } = useRangeCalendar();

  const {
    setPmList,
    setLlList,
    setStatus,
    setDateType,
    setDateFilter,
    setProjectTitle,
    setResetState,
    setIsSort
  } = setState;
  //#endregion

  const showSaveButton = useMemo(() => {
    return (
      !projectStatus.includes('ALL') ||
      !projectManagerUserIdList.includes('0') ||
      !projectAssignerUserIdList.includes('0') ||
      dateType !== 'ALL' ||
      filterData !== null
    );
  }, [
    projectStatus,
    projectManagerUserIdList,
    projectAssignerUserIdList,
    dateType,
    filterData
  ]);

  const showResetButton = useMemo(() => {
    return (
      !projectStatus.includes('ALL') ||
      !projectManagerUserIdList.includes('0') ||
      !projectAssignerUserIdList.includes('0') ||
      dateType !== 'ALL'
    );
  }, [
    projectStatus,
    projectManagerUserIdList,
    projectAssignerUserIdList,
    dateType
  ]);

  const resetState = () => {
    setResetState();
    setReset(false);
  };

  const clickRowData = (rowData: any) =>
    navigate(`/project/${rowData.projectId}`, { replace: false });

  const renderDateRangeElement = () => {
    if (
      dateType !== 'ALL' &&
      dateFilter.startDateTime &&
      dateFilter.endDateTime
    ) {
      const startLabel = format(
        new Date(dateFilter.startDateTime),
        'yyyy.MM.dd'
      );
      const endLabel = format(new Date(dateFilter.endDateTime), 'yyyy.MM.dd');

      return (
        <div className="calendar-range">
          <div>{getDateType([dateType as any])?.[0]?.label ?? ''}</div>
          <div>
            {startLabel === endLabel
              ? startLabel
              : `${startLabel} - ${endLabel}`}
          </div>
          <TPButtonClose
            onClick={() => {
              // 날짜 필터링 초기화
              setDateType(initialDataMap.dateType);
              setDateFilter(initialDataMap.dateFilter);
            }}
          />
        </div>
      );
    }

    return null;
  };

  const tableData = useMemo(
    () =>
      projectList?.map?.((e) => {
        e.allProjectAssignerList = allProjectAssignerList?.filter(
          (e) => e.userId > 0
        );
        return e;
      }),
    [projectList, allProjectAssignerList]
  );

  useLayoutEffect(() => {
    if (ref.current && ref2.current) {
      const rect = ref.current.getBoundingClientRect();
      ref2.current.style.paddingTop = `${rect.top + rect.height}px`;
    }
  });

  const notHavePermission = !myInfo?.roles?.some?.((e: string) =>
    ['PML', 'PM'].includes(e)
  );

  useEffect(() => {
    if (
      !projectStatus.includes('ALL') ||
      dateType !== 'ALL' ||
      !projectManagerUserIdList.includes('0') ||
      !projectAssignerUserIdList.includes('0') ||
      projectTitle
    ) {
      setIsFilter(true);
    }
  }, [
    projectStatus,
    dateType,
    projectManagerUserIdList,
    projectAssignerUserIdList,
    projectTitle
  ]);

  if (!isLoading && notHavePermission) throw new Error('권한이 없습니다.');

  return (
    <section css={MainWrap}>
      {calendarOpened &&
        createPortal(
          <Backdrop open sx={{ zIndex: 9999 }}>
            <Calendar />
          </Backdrop>,
          document.documentElement
        )}

      <div className="filter-toolbar" ref={ref}>
        <Box display={'flex'} flexDirection={'column'} mt={'15px'} mb={'35px'}>
          <SubTitleWrap>프로젝트</SubTitleWrap>
          <TitleWrap>프로젝트 관리</TitleWrap>
        </Box>

        <div className="row">
          <div>
            <TPSelectCheck
              label="계약 상태"
              useOptionLabel
              optionList={getStatus(['ALL', 'NEW', 'PROGRESS', 'END'])}
              selectOptions={projectStatus}
              changeSelect={setStatus}
            />
            {(myInfo?.roles.includes('PM') ||
              myInfo?.roles.includes('PML')) && (
              <TPSelectCheckManager
                label="담당 PM"
                showAvatar={true}
                PMList={pmList}
                selectOptions={projectManagerUserIdList}
                changeSelect={setPmList}
              />
            )}
            <TPSelectCheckManager
              label="담당자"
              showAvatar={true}
              PMList={projectAssignerList}
              selectOptions={projectAssignerUserIdList}
              changeSelect={setLlList}
            />
            <TPSelectOne
              label="날짜"
              optionList={getDateType([
                'ALL',
                'CONTRACT_START_DATE',
                'CONTRACT_END_DATE'
              ])}
              selectOption={dateType}
              changeSelect={(type) => {
                if (type === 'ALL') {
                  setDateType(type);
                  setDateFilter(initialDataMap.dateFilter);
                } else {
                  openHandle('project', initialDataMap.dateFilter, (range) => {
                    setDateType(type);
                    setDateFilter(range);
                  });
                }
              }}
            />
            {/* [프로젝트 관리] 필터링 저장 */}
            {showSaveButton && (
              <FilterButton
                getFilterData={{
                  projectStatus,
                  dateType,
                  projectManagerUserIdList,
                  projectAssignerUserIdList, // 담당자 추가
                  dateFilter
                  // projectTitle // 프로젝트명 제거
                }}
              />
            )}
          </div>
          <div>
            <TPInputSearchNew
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="검색"
              clickSearch={() => {
                setProjectTitle(searchValue);
              }}
            />
            {showResetButton && (
              <TPButtonReset
                style={{
                  marginLeft: '20px'
                }}
                onClick={resetState}
                border
              />
            )}
          </div>
        </div>

        <div>{renderDateRangeElement()}</div>

        {isLoadingProject || <DashBoard list={dashBoardItemList} />}
      </div>

      <div className="content" ref={ref2}>
        <MainTable
          columns={mainColumns(myInfo, isSort, setIsSort, tableData)}
          dataList={tableData}
          isLoading={isLoadingProject}
          counter={counter}
          clickColData={{
            projectTitle: clickRowData
          }}
          noRowsMessage={`${
            isFilter ? '일치하는' : '등록된'
          } 프로젝트가 없습니다.`}
        />
        {/* <DataTable
          columns={mainColumns(myInfo, isSort, setIsSort)}
          dataList={tableData}
          counter={counter}
          // clickRowData={clickRowData}
          clickColData={{
            projectTitle: clickRowData
          }}
          noRowsMessage={`${
            isFilter ? '검색된' : '등록된'
          } 프로젝트가 없습니다.`}
        />
      */}
      </div>

      <Footer />
      <Outlet />
    </section>
  );
}

export default MainPage;
