import api from '@api/Api';
import { projectKeys } from '@hooks/queryKeyFactory';
import { useHookFunc, usePageFunc } from '@hooks/utils';
import { WidgetCode, sortByAnyStatus } from '@src/common/config/Code';
import { IProjectListRes } from '@src/common/config/IProject';
import { QueryFunctionContext } from '@tanstack/query-core';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useMyInfo } from '../myInfo/getMyInfo';

/**
 * /project/list
 * @description 프로젝트 목록 조회
 * @returns {{
 *   isLoading,projectList,
 *   dashBoardItemList,projectStatus,
 *   dateType,pmManagerUserIdList,
 *   isFilter,dateFilter,title,setState
 * }}
 */
export const useGetProjectList = (filterData?: any) => {
  const oldFilterDataRef = useRef<any>();
  const { arraySet } = useHookFunc();
  const { getDateTime } = usePageFunc();
  const { myInfo } = useMyInfo();
  const [projectStatus, setProjectStatus] = useState<string[]>(
    filterData?.projectStatus ?? ['ALL']
  );
  const [dateType, setDateType] = useState<string>(
    filterData?.dateType ?? 'ALL'
  );
  const [projectAssignerUserIdList, setProjectAssignerUserIdList] = useState<
    string[]
  >(filterData?.projectAssignerUserIdList ?? ['0']);
  const [projectManagerUserIdList, setProjectManagerUserIdList] = useState<
    string[]
  >(filterData?.projectManagerUserIdList ?? ['0']);
  const [dateFilter, setDateFilter] = useState<{
    [key: string]: Date | undefined;
  }>(
    filterData?.dateFilter ?? {
      startDateTime: undefined,
      endDateTime: undefined
    }
  );
  const [projectTitle, setProjectTitle] = useState<string>(
    filterData?.projectTitle ?? ''
  );
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [isSort, setIsSort] = useState<{ [key: string]: any }>({
    code: '',
    isAsc: false
  });

  // useEffect(() => {
  //   // isFilter 상태가 변경될 때마다 로컬 스토리지에 저장
  //   localStorage.setItem('isFilter', JSON.stringify(isFilter));
  // }, [isFilter]);

  // useEffect(() => {
  //   // 너무 많은 렌더링을 방지하기 위해 사용했다.
  //   if (_.isEqual(oldFilterDataRef.current, filterData)) return;
  //   // console.debug('filterData', oldFilterDataRef.current, '->', filterData);
  //   oldFilterDataRef.current = filterData;

  //   setProjectStatus(filterData?.projectStatus ?? ['ALL']);
  //   setDateType(filterData?.dateType ?? 'ALL');
  //   setProjectAssignerUserIdList(
  //     filterData?.projectAssignerUserIdList ?? ['0']
  //   );
  //   setProjectManagerUserIdList(filterData?.projectManagerUserIdList ?? ['0']);
  //   setDateFilter(
  //     filterData?.dateFilter ?? {
  //       startDateTime: undefined,
  //       endDateTime: undefined
  //     }
  //   );
  //   setProjectTitle(filterData?.projectTitle ?? '');
  // }, [
  //   filterData,
  //   setProjectStatus,
  //   setDateType,
  //   setProjectAssignerUserIdList,
  //   setProjectManagerUserIdList,
  //   setDateFilter,
  //   setProjectTitle
  // ]);

  //#region setState
  const setState = {
    setPmList: (e: any) => {
      const getPmUserIdList =
        e === '0'
          ? ['0']
          : _.filter(
              arraySet(projectManagerUserIdList, e),
              (item) => item !== '0'
            );
      setProjectManagerUserIdList(
        getPmUserIdList.length ? getPmUserIdList : ['0']
      );
    },
    setLlList: (e: any) => {
      const getLlUserIdList =
        e === '0'
          ? ['0']
          : _.filter(
              arraySet(projectAssignerUserIdList, e),
              (item) => item !== '0'
            );
      setProjectAssignerUserIdList(
        getLlUserIdList.length ? getLlUserIdList : ['0']
      );
    },
    setStatus: (e: any) => {
      const getStatus =
        e === 'ALL'
          ? ['ALL']
          : _.filter(arraySet(projectStatus, e), (item) => item !== 'ALL');
      setProjectStatus(getStatus.length ? getStatus : ['ALL']);
    },
    setDateType,
    setDateFilter,
    setProjectTitle,
    setResetState: () => {
      setProjectStatus(['ALL']);
      setProjectAssignerUserIdList(['0']);
      setProjectManagerUserIdList(['0']);
      setDateType('ALL');
      setDateFilter({ startDateTime: undefined, endDateTime: undefined });
      setProjectTitle('');
    },
    setIsSort
  };
  //#endregion

  //#region payload set
  const setPayload = ({
    projectStatus,
    dateType,
    projectAssignerUserIdList,
    projectManagerUserIdList,
    dateFilter,
    projectTitle
  }: {
    projectStatus: string[];
    dateType: string;
    projectAssignerUserIdList: string[];
    projectManagerUserIdList: string[];
    dateFilter: { [key: string]: any };
    projectTitle: string;
  }) => {
    const { startDateTime, endDateTime } = dateFilter;

    // 필터 조건 확인
    const hasFilters = Boolean(
      projectTitle ||
        !projectStatus.includes('ALL') ||
        !projectAssignerUserIdList.includes('0') ||
        !projectManagerUserIdList.includes('0') ||
        (dateType !== 'ALL' && startDateTime && endDateTime)
    );

    setIsFilter(hasFilters);

    return {
      isFilter: hasFilters, // boolean 값으로만 전송
      ...(projectTitle && { projectTitle }),
      ...(!projectStatus.includes('ALL') && { projectStatus }),
      ...(!projectAssignerUserIdList.includes('0') && {
        projectAssignerUserIdList
      }),
      ...(!projectManagerUserIdList.includes('0') && {
        projectManagerUserIdList
      }),
      ...(dateType !== 'ALL' &&
        startDateTime &&
        endDateTime && {
          dateType,
          startDateTime: getDateTime({
            date: new Date(startDateTime),
            filter: 'start'
          }),
          endDateTime: getDateTime({
            date: new Date(endDateTime),
            filter: 'end'
          })
        })
    };
  };
  //#endregion

  //#region api call
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof projectKeys)['list']>
  >): Promise<IProjectListRes> => {
    const [, , filters] = queryKey;
    const getPayload = setPayload(filters);
    return await api.post('/project/list', getPayload).then((res) => res.data);
  };

  useEffect(() => {
    if (!filterData) return;

    setProjectStatus(filterData.projectStatus ?? ['ALL']);
    setDateType(filterData.dateType ?? 'ALL');
    setProjectAssignerUserIdList(filterData.projectAssignerUserIdList ?? ['0']);
    setProjectManagerUserIdList(filterData.projectManagerUserIdList ?? ['0']);
    setDateFilter(
      filterData.dateFilter ?? {
        startDateTime: undefined,
        endDateTime: undefined
      }
    );
    setProjectTitle(filterData.projectTitle ?? '');
  }, [filterData]);

  //#endregion
  //#region useQuery define
  const projectQuery = useQuery(
    [
      ...projectKeys.list({
        projectStatus,
        dateType,
        projectAssignerUserIdList,
        projectManagerUserIdList,
        dateFilter,
        projectTitle
      })
    ],
    getData,
    {
      suspense: true,
      enabled: filterData !== undefined,
      select: (data) => {
        const counter = {
          newCount: 0,
          completeAssignCount: 0,
          progressCount: 0,
          completeCount: 0,
          deliveryCompleteCount: 0,
          stopCount: 0
        };

        const list = {
          projectList: [...data.projectList],
          counter
        };

        switch (isSort.code) {
          case 'companyName':
          case 'projectTitle':
            console.debug([
              isSort.code,
              ..._.filter(
                ['companyName', 'projectTitle'],
                (e) => e !== isSort.code
              )
            ]);
            list.projectList = _.orderBy(
              data.projectList,
              [
                isSort.code,
                ..._.filter(
                  ['companyName', 'projectTitle'],
                  (e) => e !== isSort.code
                )
              ],
              [isSort.isAsc ? 'asc' : 'desc', 'asc']
            );
            break;
          case 'mainProjectManager':
            list.projectList = _.orderBy(
              data.projectList,
              [
                // 현재 로그인한 사용자가 메인/서브 PM인지 여부로 정렬
                (item) => {
                  const isMainPM =
                    item.mainProjectManager?.userId === myInfo?.id;
                  const isSubPM = item.subProjectManager?.userId === myInfo?.id;

                  if (isSort.isAsc) {
                    // 오름차순: 메인PM -> 서브PM -> 기타
                    if (isMainPM) return 0;
                    if (isSubPM) return 1;
                    return 2;
                  } else {
                    // 내림차순: 서브PM -> 메인PM -> 기타
                    if (isSubPM) return 0;
                    if (isMainPM) return 1;
                    return 2;
                  }
                },
                // 2차 정렬: 회사명, 프로젝트명
                'companyName',
                'projectTitle'
              ],
              ['asc', 'asc', 'asc'] // 첫 번째 기준만 변경되고 나머지는 항상 오름차순
            );
            break;
          case 'startDateTime':
          case 'endDateTime':
            list.projectList = _.orderBy(
              data.projectList,
              [isSort.code, 'companyName', 'projectTitle'],
              [isSort.isAsc ? 'asc' : 'desc', 'asc', 'asc']
            );
            break;
          default:
            list.projectList = _.orderBy(
              data.projectList,
              ['companyName', 'projectTitle'],
              ['asc']
            );
            break;
        }

        list.projectList.forEach((e: any) => {
          if (typeof e.newCount === 'number' && e.newCount > 0)
            counter.newCount += e.newCount;
          if (
            typeof e.completeAssignCount === 'number' &&
            e.completeAssignCount > 0
          )
            counter.completeAssignCount += e.completeAssignCount;
          if (typeof e.progressCount === 'number' && e.progressCount > 0)
            counter.progressCount += e.progressCount;
          if (typeof e.completeCount === 'number' && e.completeCount > 0)
            counter.completeCount += e.completeCount;
          if (
            typeof e.deliveryCompleteCount === 'number' &&
            e.deliveryCompleteCount > 0
          )
            counter.deliveryCompleteCount += e.deliveryCompleteCount;
          if (typeof e.stopCount === 'number' && e.stopCount > 0)
            counter.stopCount += e.stopCount;
        });
        return list;
      }
    }
  );

  // 상단 count
  const dashBoardItemList = WidgetCode.widgetMain.map((e: any) => {
    const s =
      projectQuery.data?.projectList?.filter?.(
        (p: any) => p.projectStatus === e.icon
      ) ?? [];
    return {
      ...e,
      count: s.length
    };
  });

  return {
    isLoading: projectQuery.isLoading,
    projectList: projectQuery.data?.projectList ?? [],
    refetchProjectList: projectQuery.refetch,
    projectQuery,
    counter: projectQuery.data?.counter ?? {},
    dashBoardItemList,
    projectStatus,
    dateType,
    projectAssignerUserIdList,
    projectManagerUserIdList,
    dateFilter,
    projectTitle,
    isFilter,
    setIsFilter,
    isSort,
    setState
  };
};
