import { Box } from '@mui/material';
import { useToast } from '@src/hooks/modal/useToast';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FileUpdateStyle } from './FileUpdate.style';
import { alertWrap } from '@src/hooks/modal/Modal.style.v2';
import { useAlert } from '@src/hooks/modal/useAlert';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { TPInputCheckBox } from '@src/units/input/TPInput.checkBox';
import { useGetFileUpload } from '@src/hooks/file/getFileUpload';
import {
  fileStatus,
  getStatusLabel,
  getTaskStatusLabelColor
} from '@src/common/config/Code';
import { TPInputCheckBoxBasic } from '@src/units/input/TPInput.checkBoxBasic';
import { renderLanguageByCode } from '../dashboard/utils/render';
import { IconFileOff, IconFileOn } from '@src/resources/icon';
import { TPInputRadio } from '@src/units/input/TPInput.radio';

export interface IFileUploadStatus {
  languageTaskIdList: number[];
  fileRegisterStatus: boolean;
}

export const FileUpdatePage = () => {
  const { id, jobDetailId } = useParams();
  const navigate = useNavigate();
  const { openHandle: alertHandle } = useAlert();
  const { data: fileUploadData, putStatus } = useGetFileUpload(
    jobDetailId ? Number(jobDetailId) : 0
  );
  const [fileRegisterStatus, setFileRegisterStatus] = useState('REGISTERED');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [fileUploadStatus, setFileUploadStatus] = useState<IFileUploadStatus>({
    languageTaskIdList: [],
    fileRegisterStatus: true
  });

  // 체크박스 클릭 핸들러
  const handleCheckboxClick = useCallback(
    (key: string) => {
      const validItems =
        fileUploadData?.languageTaskList.filter(
          (item: any) =>
            (item.languageTaskStatus === 'NEW' ||
              item.languageTaskStatus === 'COMPLETE_ASSIGN') &&
            !item.isBoracatUseOrNot
        ) || [];

      const validItemIds = validItems.map((item: any) =>
        item.languageTaskId.toString()
      );

      setSelectedItems((prev) => {
        let newSelection: string[];
        if (key === 'ALL') {
          newSelection = prev.includes('ALL') ? [] : ['ALL', ...validItemIds];
        } else {
          // 선택한 항목이 유효한 항목인지 확인
          if (!validItemIds.includes(key)) {
            return prev;
          }

          if (prev.includes(key)) {
            newSelection = prev.filter((item) => item !== key);
            newSelection = newSelection.filter((item) => item !== 'ALL');
          } else {
            newSelection = [...prev, key];
            newSelection =
              newSelection.filter((id) => id !== 'ALL').length ===
              validItemIds.length
                ? ['ALL', ...newSelection]
                : newSelection;
          }
        }

        // fileUploadStatus 업데이트 - 'ALL'을 제외한 선택된 항목들을 숫자로 변환
        setFileUploadStatus((prev) => ({
          ...prev,
          languageTaskIdList: newSelection
            .filter((item) => item !== 'ALL')
            .map(Number)
        }));

        return newSelection;
      });
    },
    [fileUploadData]
  );

  // 파일 상태 변경 취소
  const closeModal = () => {
    alertHandle({
      title: '작업 파일 등록 상태 변경 취소',
      text: '입력 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        const { state } = window.history;
        if (state?.projectId && state?.taskId) {
          window.history.replaceState({}, '');
        } else {
          navigate(-1);
        }
      }
    });
  };

  // 파일 상태 변경 확인
  const onSubmit = () => {
    alertHandle({
      title: '작업 파일 등록 상태 변경',
      text: '작업 파일 등록 상태를 변경하겠습니까?',
      cancelLabel: '취소',
      ok: () => {
        putStatus(fileUploadStatus);
      }
    });
  };

  return (
    <div css={[FileUpdateStyle, alertWrap]}>
      <TPButtonClose className="alertClose" onClick={closeModal} />
      <h2 className="alertTitle">작업 파일 등록 상태 관리</h2>

      <div className="task--info">
        <div>
          <span className="task--status">
            <span
              style={{
                backgroundColor: getTaskStatusLabelColor(
                  fileUploadData?.taskStatus
                )
              }}
            ></span>
            {getStatusLabel(fileUploadData?.taskStatus)}
          </span>
          <p>{fileUploadData?.taskKey}</p>
        </div>
        <p>{fileUploadData?.taskTitle}</p>
      </div>
      <div className="sub--task--wrap">
        <div className="titlebox">
          <strong>언어 작업</strong>
          <p>작업 환경이 보라캣일 경우 수정이 불가합니다.</p>
          <span>
            <TPInputCheckBoxBasic
              option="전체 선택"
              optionKey="ALL"
              selectOptions={selectedItems}
              onClick={handleCheckboxClick}
            />
          </span>
        </div>
      </div>
      <div className="file--checkbox--wrap">
        {fileUploadData?.languageTaskList.map((item: any) => (
          <div key={item.languageTaskId}>
            <div className="file--checkbox--item">
              <div className="file--checkbox--item--left">
                <span
                  className={
                    (item.languageTaskStatus !== 'NEW' &&
                      item.languageTaskStatus !== 'COMPLETE_ASSIGN') ||
                    item.isBoracatUseOrNot
                      ? 'disabled'
                      : ''
                  }
                >
                  <TPInputCheckBoxBasic
                    option=""
                    optionKey={item.languageTaskId.toString()}
                    selectOptions={selectedItems}
                    onClick={handleCheckboxClick}
                    disabled={
                      (item.languageTaskStatus !== 'NEW' &&
                        item.languageTaskStatus !== 'COMPLETE_ASSIGN') ||
                      item.isBoracatUseOrNot
                    }
                  />
                </span>
                <span className="task--status">
                  <span
                    style={{
                      backgroundColor: getTaskStatusLabelColor(
                        item.languageTaskStatus
                      )
                    }}
                  ></span>
                  {getStatusLabel(item.languageTaskStatus)}
                </span>
              </div>
              <div className="file--checkbox--item--right">
                {renderLanguageByCode(item.startLanguage as string)}
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 9L5.24264 4.75736M1 1.5014L5.24264 5.74405"
                    stroke="#242424"
                    strokeWidth="1.4"
                  />
                </svg>
                {renderLanguageByCode(item.destinationLanguage as string)}
              </div>
            </div>
            <p className="file--title">{item.languageTaskTitle}</p>
            <span className="file--status">
              {item.fileRegisterStatus ? <IconFileOn /> : <IconFileOff />}
            </span>
          </div>
        ))}
      </div>

      <div className="file--upload--wrap">
        <TPInputRadio
          value={fileRegisterStatus}
          label={'파일 등록 여부'}
          required={true}
          name={'fileRegisterStatus'}
          options={fileStatus}
          changeRadio={(e: any) => {
            setFileUploadStatus({
              ...fileUploadStatus,
              fileRegisterStatus: e.target.value === 'REGISTERED' ? true : false
            });
            setFileRegisterStatus(e.target.value);
          }}
        />
      </div>

      <Box height={'32px'} />
      <Box display={'flex'} justifyContent={'space-between'} gap={2}>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="outlined"
            label="취소"
            fullWidth
            onClick={closeModal}
            size={'large'}
          />
        </Box>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="contained"
            label="수정하기"
            fullWidth
            onClick={onSubmit}
            size={'large'}
            disabled={fileUploadStatus.languageTaskIdList.length === 0}
          />
        </Box>
      </Box>
    </div>
  );
};
