import _ from 'lodash';
import { useGetHolidays } from '../statistics/getHolidays';
import { LANGUAGE } from '@common/config/Code';
/**
 * @description hooks에서 공통으로 사용하는 함수
 * @returns {{arraySet,defaultUserList}}
 */
export const useHookFunc = () => {
  const arraySet = (arr: any[], e: string) =>
    arr.includes(e) ? _.filter(arr, (item) => item !== e) : [...arr, e];

  //todo 함수는 아니지만 일단 여기 모아둔다...
  const defaultUserList = [{ name: '전체', userId: 0, avatarImgUrl: '' }];

  return { arraySet, defaultUserList };
};

/**
 * @description todo 담당자 배정 모달인데 이거 왜 같이 쓰는지 모르겠다.
 * @returns {{arraySet,defaultUserList}}
 */
export const useHookLLAssignFunc = () => {
  const arraySet = (arr: any[], e: string) =>
    arr.includes(e) ? _.filter(arr, (item) => item !== e) : [...arr, e];

  //todo 함수는 아니지만 일단 여기 모아둔다...
  const defaultUserList: {
    name: string;
    userId: number;
    avatarImgUrl: string;
  }[] = [];

  return { arraySet, defaultUserList };
};

/**
 * @description 페이지에서 공통으로 사용하는 함수
 * @returns {{
 *    formattedString,getStatus,
 *    taskAmountText,taskAmountRate,workerType
 * }}
 */
export const usePageFunc = () => {
  /** usePageFunc가 전역으로 사용되어 모든 페이지에서 사용되는 것을 방지하기 위해 주석처리 ( 추후 useGetHolidays 조회 기능 추가 시 주석 해제하여 사용 + 사용시143번째 line 주석 해제 필요 )
  const { holidays: holidaysArr } = useGetHolidays();*/

  const formattedString = (dateTime: Date | undefined): string => {
    const originalDate = dateTime ? new Date(dateTime) : new Date();
    const date = originalDate.toLocaleDateString().replace(/\//g, '.');
    const time = originalDate.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    return `${date} ${time}`;
  };

  const getStatus = (status: string): string => {
    switch (status) {
      case 'NEW':
        return '진행 전';
      case 'PROGRESS':
        return '진행 중';
      case 'COMPLETE_TASK':
        return '완료';
      case 'REJECT':
        return '반려';
      case 'STOP':
        return '중지';
      default:
        return '진행 전';
    }
  };

  const getDateTime = ({
    date,
    filter = undefined
  }: {
    date: Date;
    filter?: 'start' | 'end';
  }) => {
    const utcDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
    const resultDate = new Date(
      Date.UTC(
        utcDate.getUTCFullYear(),
        utcDate.getUTCMonth(),
        utcDate.getUTCDate(),
        utcDate.getUTCHours(),
        utcDate.getUTCMinutes(),
        utcDate.getUTCSeconds(),
        utcDate.getUTCMilliseconds()
      )
    );

    switch (filter) {
      case 'start':
        return new Date(resultDate.setUTCHours(0, 0, 0, 0));
      case 'end':
        return new Date(resultDate.setUTCHours(23, 59, 59, 0));
      default:
        return resultDate;
    }
  };

  //todo
  const workerType = ['TRANSLATOR', 'FIRST_CHECKER', 'SECOND_CHECKER'];
  const COUNT_COLOR = ['#ffcccc', '#ff9999', '#ff6666', '#ff5533', '#ff0000'];

  const taskAmountRate = {
    percent101: 0,
    percentRep: 0.1,
    percent100: 0.1,
    percent9599: 0.7,
    percent8594: 0.7,
    percent7584: 0.7,
    percent5074: 1.0,
    noMatch: 1.0
  };

  const taskAmountText: { [key: string]: any } = {
    percent101: '101%',
    percentRep: 'Rep',
    percent100: '100%',
    percent9599: '95%~99%',
    percent8594: '85%~94%',
    percent7584: '75%~84%',
    percent5074: '50%~74%',
    noMatch: 'No match'
  };

  const taskAmountTextNew: { [key: string]: any } = {
    percent101: '101%',
    percentRep: 'Rep',
    percent100: '100%',
    percent9599: '95%~99%',
    percent8594: '85%~94%',
    percent7584: '75%~84%',
    percent5074: '50%~74%',
    noMatch: 'No match'
  };

  const holidays = (key: string, day: string) => {
    // return holidaysArr[key]?.includes(day);
    return false;
  };

  const lankColor = (count: number, holidays: boolean) => {
    const point = Math.floor((count - 2000) / 200);
    if (!holidays && point !== 0) {
      return `style="background-color:${
        point > 0
          ? point > 5
            ? COUNT_COLOR[4]
            : COUNT_COLOR[point - 1]
          : '#71d88e99'
      };"`;
    }
    return '';
  };

  const sortingLanguagePairList = (el: any) => {
    return _.chain(el)
      .sortBy([
        (item) => _.findIndex(LANGUAGE, { code: item.startLanguage }),
        (item) => _.findIndex(LANGUAGE, { code: item.destinationLanguage })
      ])
      .value();
  };

  const formatNumber = (number: number) => {
    const suffixes = ['', 'k', 'm', 'b'];
    const magnitude = Math.floor(Math.log10(number) / 3);
    const scaledNumber = number / Math.pow(10, magnitude * 3);
    return (
      (isNaN(scaledNumber) ? 0 : scaledNumber).toFixed(1) +
      (suffixes[magnitude] ?? '')
    );
  };

  const maxCount = (minMax: boolean, data: any[]) => {
    if (data.length) {
      const maxSum = _.maxBy(data, 'sum').sum;
      const minSum = _.minBy(data, 'sum').sum;
      const maxCount = Math.ceil((maxSum - minSum) / 10) * 10;

      return (minMax ? (maxCount < 2000 ? 2000 : maxCount) : maxCount) + minSum;
    } else {
      return 0;
    }
  };

  return {
    formattedString,
    getStatus,
    holidays,
    lankColor,
    getDateTime,
    sortingLanguagePairList,
    formatNumber,
    maxCount,
    COUNT_COLOR,
    taskAmountRate,
    taskAmountText,
    taskAmountTextNew,
    workerType
  };
};
