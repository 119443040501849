import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import DoneDateFilter, {
  useDoneDateFilterProps
} from '@src/pages/dashboard/units/DoneDateFilter';
import FutureDateFilter, {
  useFutureDateFilterProps
} from '@src/pages/dashboard/units/FutureDateFilter';
import {
  DONE_TOOLTIP_MESSAGE,
  FUTURE_TOOLTIP_MESSAGE
} from '@src/pages/dashboard/units/Row2TaskList';
import {
  IcoMenuChevronDown,
  IconInfoBlack,
  IconLink
} from '@src/resources/icon';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyWorkTable } from '@src/pages/myTask/MyTaskTab.page';
import { useGetMyTaskListByDay } from '@src/hooks/myWork/getListByDay';

export type CategoryTableProps = {
  item: {
    label: string;
    items: number;
    order: number;
    autoCall: boolean;
    actionChain: string;
    url?: string;
  };
  type: 'mytask' | 'task';
  taskFilter: any;
  setCateGoryCounts: Dispatch<SetStateAction<any[]>>;
  isFilter: boolean;
};

export default function CategoryTableMyTask({
  item: { label, items, order, autoCall, actionChain, url },
  taskFilter,
  type,
  setCateGoryCounts,
  isFilter
}: CategoryTableProps) {
  const to = useNavigate();
  const [open, setOpen] = useState(Boolean(autoCall));
  const futureDateFilter = useFutureDateFilterProps();
  const doneDateFilter = useDoneDateFilterProps();
  const [boardStatus, setBoardStatus] = useState([]);
  const {
    data: myWorkListByDayData,
    isLoading,
    isSuccess,
    setFilter: myWorkFilter
  } = useGetMyTaskListByDay();

  // taskFilter 변경 시 데이터 재조회
  useEffect(() => {
    myWorkFilter({
      label,
      ...taskFilter,
      ...(label === 'FUTURE' ? futureDateFilter : {}),
      ...(label === 'DONE' ? doneDateFilter : {})
    });
  }, [taskFilter]);

  // 날짜 필터 변경 시 데이터 업데이트
  useEffect(() => {
    if (open || ['FUTURE', 'DONE'].includes(label)) {
      myWorkFilter({
        label,
        ...taskFilter,
        ...(label === 'FUTURE' ? futureDateFilter : {}),
        ...(label === 'DONE' ? doneDateFilter : {})
      });
    }
  }, [
    futureDateFilter.dateType,
    futureDateFilter.startDateTime,
    futureDateFilter.endDateTime,
    doneDateFilter.dateType,
    doneDateFilter.startDateTime,
    doneDateFilter.endDateTime
  ]);

  // 데이터 상태에 따른 처리
  useEffect(() => {
    if (isSuccess) {
      const hasData = myWorkListByDayData?.myWorkList?.length > 0;

      // 데이터가 있거나 FUTURE/DONE 카테고리인 경우 열기
      if (hasData || ['FUTURE', 'DONE'].includes(label)) {
        setOpen(true);
      } else {
        setOpen(false);
      }

      // 카운트 업데이트
      if (hasData) {
        const statusCounts: Record<string, number> = {};
        myWorkListByDayData.myWorkList.forEach((item: any) => {
          const status = item.workerStatus;
          statusCounts[status] = (statusCounts[status] || 0) + 1;
        });

        setCateGoryCounts((prev: any) => ({
          ...prev,
          [label]: statusCounts
        }));
      } else {
        // 데이터가 없는 경우 해당 카테고리 카운트 제거
        setCateGoryCounts((prev: any) => {
          const newCounts = { ...prev };
          delete newCounts[label];
          return newCounts;
        });
      }
    }
  }, [myWorkListByDayData, isSuccess]);

  if (isLoading && !isSuccess) return <div>Loading...</div>;

  return (
    <CategoryTableWrap className={cx({ open })}>
      <div className="toggle">
        <div
          className="category"
          onClick={() => {
            setOpen((v) => !v);
          }}
        >
          <IcoMenuChevronDown className={cx({ open })} />
          <div>{label}</div>
          <div className={`chip ${label}`}>
            {label === 'FUTURE' || label === 'DONE'
              ? myWorkListByDayData?.myWorkList?.length
              : items}
          </div>
          {url && (
            <span
              {...{
                style: { cursor: 'pointer' },
                tile: url,
                onClick(e) {
                  e.preventDefault();
                  e.stopPropagation();
                  const v = url?.trim?.()?.toLowerCase?.();
                  if (v) to(v);
                }
              }}
            >
              <IconLink
                style={{
                  transform: 'translate(8px, 2px)'
                }}
              />
            </span>
          )}
        </div>
      </div>
      {open && (
        <>
          {label === 'FUTURE' && (
            <>
              <div className="filter-toolbar">
                <strong>조회기간</strong>
                <SimpleTooltip message={FUTURE_TOOLTIP_MESSAGE} position="top">
                  <IconInfoBlack width={16} height={16} />
                </SimpleTooltip>
                <FutureDateFilter
                  {...futureDateFilter}
                  disabledHopeSupplyDate
                />
              </div>
              <MyWorkTable
                label={label}
                data={myWorkListByDayData}
                taskFilter={{
                  ...taskFilter,
                  ...{
                    dateType: futureDateFilter.dateType,
                    startDateTime: futureDateFilter.startDateTime,
                    endDateTime: futureDateFilter.endDateTime
                  }
                }}
                setOpen={setOpen}
                setCateGoryCounts={setCateGoryCounts}
                isFilter={isFilter}
              />
            </>
          )}
          {label === 'DONE' && (
            <>
              <div className="filter-toolbar">
                <strong>조회기간</strong>
                <SimpleTooltip message={DONE_TOOLTIP_MESSAGE} position="top">
                  <IconInfoBlack width={16} height={16} />
                </SimpleTooltip>
                <DoneDateFilter {...doneDateFilter} disabledHopeSupplyDate />
              </div>
              <MyWorkTable
                label={label}
                data={myWorkListByDayData}
                taskFilter={{
                  ...taskFilter,
                  ...{
                    dateType: doneDateFilter.dateType,
                    startDateTime: doneDateFilter.startDateTime,
                    endDateTime: doneDateFilter.endDateTime
                  }
                }}
                setOpen={setOpen}
                setCateGoryCounts={setCateGoryCounts}
                isFilter={isFilter}
              />
            </>
          )}
          {label !== 'FUTURE' && label !== 'DONE' && (
            <MyWorkTable
              label={label}
              data={myWorkListByDayData}
              taskFilter={taskFilter}
              setOpen={setOpen}
              setCateGoryCounts={setCateGoryCounts}
              isFilter={isFilter}
            />
          )}
        </>
      )}
    </CategoryTableWrap>
  );
}

const CategoryTableWrap = styled.div`
  width: 1820px;

  .toggle {
    margin-top: 36px;
    min-width: 1820px;
    :where(:not(.open .toggle)) {
      background-color: #fff;
    }
  }

  .category {
    min-width: 1820px;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    height: 46px;

    font-family: 'Spoqa Han Sans Neo', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #242424;

    border-radius: 2px;
    padding: 5px 14px;
    cursor: pointer;
    user-select: none;

    > svg {
      transform: rotate(-90deg);
      &.open {
        transform: rotate(0deg);
      }
    }

    .chip {
      background-color: #242424;
      border-radius: 30px;
      color: #fff;
      text-align: center;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 10px;
      font-weight: 700;
      line-height: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 23px;
      min-height: 15px;
      padding: 2px 4px;
    }
  }
  .filter-toolbar {
    z-index: 1052;
    display: inline-flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
    white-space: nowrap;
    .FilterInput .label--selectOption + svg {
      margin-left: auto;
    }

    & > *:first-of-type {
      transform: translate(2px, -6px);
    }

    .main--button--refresh {
      margin-bottom: 16px;
      border: 1px solid #cbcbcb;
      padding: 5px 14px;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: -0.25px;
      text-align: left;
      color: #242424;
      background-color: #fff;

      &:hover {
        color: #9b51e0;
      }
    }
  }
`;
