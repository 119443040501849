import React, { useEffect, useState } from 'react';
import {
  IconArrowRightRadius,
  IconArrowDownRadius,
  IconCloseWhite,
  IconBasicUserUrl,
  IconDefaultProfileUrl
} from '@src/resources/icon';
import { usePageFunc } from '@src/hooks/utils';
import { TaskAmountInputsNew } from '../components/TaskAmountInputsNew.Comp';
import { useTaskerSearchBookMark } from '@hooks/modal/useTaskerSearchBookMark';
import _ from 'lodash';
import { ILanguageSubTaskNew } from '@src/common/config/ILanguageTask';
import { ITaskDataNew } from '@src/common/config/ITask';
import { TPSelectBasic } from '@src/units/select/TPSelect.basic';
import { useToast } from '@src/hooks/modal/useToast';
import { useDeleteSubTask } from '@src/hooks/languageTask/deleteSubTask';

interface TaskListProps {
  data: ITaskDataNew;
  newList: ILanguageSubTaskNew[];
  setNewList: React.Dispatch<React.SetStateAction<ILanguageSubTaskNew[]>>;
  totalAmount: { totalGrossAmount: number };
  tmMatch: boolean;
  taskAmountList: { [key: string]: any };
  changeInput: (
    data: { [key: string]: any },
    key?: string,
    AmountListKey?: string
  ) => void;
  languageTaskKey: string;
  inputValues: { [key: string]: string };
  setInputValues: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  inputLength: {
    [key: string]: number;
  };
  setInputLength: React.Dispatch<
    React.SetStateAction<{
      [key: string]: number;
    }>
  >;
  resetTrigger: boolean;
  isPM: boolean;
}

interface WorkerOrder {
  TRANSLATOR: number;
  SECOND_CHECKER: number;
  FIRST_CHECKER: number;
  [key: string]: number;
}

interface Worker {
  workerType?: string;
  avatarImgUrl?: string;
  name?: string;
  workerStatus?: string;
  userId: number;
  [key: string]: any;
}

export const JobAssignTaskList: React.FC<TaskListProps> = ({
  data,
  newList,
  setNewList,
  tmMatch,
  changeInput,
  inputValues,
  setInputValues,
  inputLength,
  setInputLength,
  resetTrigger,
  isPM = false
}) => {
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedTaskIndex, setSelectedTaskIndex] = useState<number | null>(
    null
  );
  const [selectedWorkerIndex, setSelectedWorkerIndex] = useState<number | null>(
    null
  );
  const taskStatus = data.languageTaskStatus ?? '';
  const languageSubTaskList = data.languageSubTaskList ?? [];
  const { taskAmountTextNew } = usePageFunc();
  const [taskSizes, setTaskSizes] = useState<Record<string, boolean>>({});
  const {
    openHandle: taskerSearchHandle,
    Component: TaskerSearchBookMark,
    open
  } = useTaskerSearchBookMark();
  const { openHandle: toastHandle } = useToast();
  const { deleteSubTaskAlert } = useDeleteSubTask();
  const handleInputChange = (fieldId: string, value: string) => {
    setNewList((prevList) =>
      prevList.map((task) => {
        if (task.languageSubTaskKey === fieldId) {
          return {
            ...task,
            memo: value,
            memoWarning: value.length > 30
          };
        }
        return task;
      })
    );
    setInputLength((prev) => ({
      ...prev,
      [fieldId]: value.length
    }));
    setInputValues((prev) => ({
      ...prev,
      [fieldId]: value
    }));
  };

  const toggleTaskSize = (key: string) => {
    setTaskSizes((prevSizes) => ({
      ...prevSizes,
      [key]: !prevSizes[key]
    }));
  };

  useEffect(() => {
    if (!open) {
      setSelectedTaskIndex(null);
      setSelectedWorkerIndex(null);
    }
  }, [open]);

  useEffect(() => {
    const initialStates = Object.keys(inputValues).reduce<{
      values: { [key: string]: string };
      lengths: { [key: string]: number };
    }>(
      (acc, key) => {
        const foundTask = newList.find(
          (task) => task.languageSubTaskKey === key
        );
        if (foundTask && foundTask.memo != null) {
          acc.values[key] = foundTask.memo;
          acc.lengths[key] = foundTask.memo.length;
        } else {
          acc.values[key] = '';
          acc.lengths[key] = 0;
        }
        return acc;
      },
      { values: {}, lengths: {} }
    );
    setInputValues(initialStates.values);
    setInputLength(initialStates.lengths);
  }, [resetTrigger]);

  const clickAssigning = (rowIdx: number, workerIdx: number) => {
    const workerList = newList[rowIdx]?.workerList!;
    if (workerIdx > 0 && workerList[workerIdx - 1].name === '') {
      setTimeout(() => {
        toastHandle({
          text: `이전 작업자가 배정되지 않았습니다.`,
          severity: 'error'
        });
      }, 50);
      return;
    }

    setSelectedTaskIndex(rowIdx);
    setSelectedWorkerIndex(workerIdx);
    taskerSearchHandle(
      (select: Partial<Worker>, rowIdx: number, workerIdx: number) => {
        setSelectedUserId(select.userId!);

        setNewList((prevList) => {
          const newList = [...prevList];
          const selectedTask = newList[rowIdx];
          const updatedWorkers =
            selectedTask.workerList &&
            selectedTask.workerList.map((worker, index) => {
              if (index === workerIdx) {
                const updatedWorker = { ...worker } as any;
                Object.keys(worker).forEach((key) => {
                  if (select.hasOwnProperty(key)) {
                    updatedWorker[key] = select[key];
                  }
                });
                return updatedWorker;
              }
              return worker;
            });

          selectedTask.workerList = updatedWorkers;
          return newList;
        });
      },
      rowIdx,
      workerIdx
    );
  };

  // const removeEventListener =
  //   (listNum: ILanguageSubTaskNew) =>
  //   (event: React.MouseEvent<HTMLButtonElement>) => {
  //     setNewList((prevList) => prevList.filter((task) => task !== listNum));
  //   };

  const removeEventListener = (listNum: ILanguageSubTaskNew) => async () => {
    const existingTask = data.languageSubTaskList?.find(
      (task) => task.languageSubTaskId === listNum.languageSubTaskId
    );

    if (existingTask) {
      const confirmed = await deleteSubTaskAlert({
        id: listNum.languageSubTaskId ?? 0
      });
      if (!confirmed) return;
    }

    setNewList((prevList) => prevList.filter((task) => task !== listNum));
  };

  const clickUnassign = (rowIdx: number, workerIdx: number) => {
    if (
      workerIdx < 2 &&
      newList[rowIdx]?.workerList?.[workerIdx + 1]?.userId !== 0
    ) {
      toastHandle({
        text: `${
          workerIdx === 1 ? '2차 ' : ''
        }검수 작업자가 있어 배정 취소가 불가능합니다.`,
        severity: 'error'
      });
      return;
    }
    setNewList((prevList) => {
      const newList = [...prevList];
      const selectedTask = newList[rowIdx];
      const updatedWorkers =
        selectedTask.workerList &&
        selectedTask.workerList.map((worker, index) => {
          if (index === workerIdx) {
            const updatedWorker = { ...worker };
            updatedWorker.userId = 0;
            updatedWorker.name = '';
            updatedWorker.avatarImgUrl = '';
            updatedWorker.slackId = '';
            return updatedWorker;
          }
          return worker;
        });
      selectedTask.workerList = updatedWorkers;
      return newList;
    });
  };

  // 컴포넌트 마운트 시 초기 시간 값 설정
  useEffect(() => {
    setNewList((prevList) =>
      prevList.map((subTask) => {
        if (subTask.languageSubTaskId === 0 && subTask.taskAmount === 0) {
          const totalAmount = data.totalGrossAmount;
          return {
            ...subTask,
            totalAmount: totalAmount,
            taskAmount: totalAmount
          };
        }
        return subTask;
      })
    );
  }, [data.totalGrossAmount]);

  const handleOutsourceChange = (workerType: string, taskIndex: number) => {
    setNewList((prevList) =>
      prevList.map((subTask, index) => {
        if (index === taskIndex) {
          const updatedWorkers = subTask?.workerList?.map((subWorker) => {
            if (
              subWorker.workerType === workerType &&
              subTask.languageSubTaskKey ===
                prevList[taskIndex].languageSubTaskKey
            ) {
              return {
                ...subWorker,
                isOutsourceOrNot: !subWorker.isOutsourceOrNot
              };
            }
            return subWorker;
          });
          return {
            ...subTask,
            workerList: updatedWorkers
          };
        }
        return subTask;
      })
    );
  };

  return (
    <div className="taskWrap">
      {open && <TaskerSearchBookMark />}
      {newList.map((task, taskIndex: number) => {
        return (
          <div className="task--list" key={taskIndex}>
            <p className="task--title">
              <button onClick={() => toggleTaskSize(task.languageSubTaskKey)}>
                {taskSizes[task.languageSubTaskKey] ? (
                  <IconArrowRightRadius />
                ) : (
                  <IconArrowDownRadius />
                )}
              </button>
              {task.languageSubTaskKey}
            </p>
            {taskSizes[task.languageSubTaskKey] || (
              <div>
                <div>
                  <div className="sub--title--box">
                    <strong className="sub--title">
                      작업 분량 <span className="required">*</span>
                      {data.taskType !== 'LQA' && (
                        <span className="task--amount">{task.totalAmount}</span>
                      )}
                    </strong>
                    {data.taskType !== 'LQA' ? (
                      <div>
                        <TaskAmountInputsNew
                          tmMatch={tmMatch}
                          taskKey={task.languageSubTaskKey}
                          taskAmountList={
                            (data.languageSubTaskList.length === 0
                              ? task.languageSubTaskKey.split('-').pop() ===
                                '01'
                                ? data.languageTaskAmount
                                : task.languageSubTaskAmount
                              : task.languageSubTaskAmount) ?? {}
                          }
                          inputText={taskAmountTextNew}
                          changeInput={changeInput}
                          resetTrigger={resetTrigger}
                        />
                      </div>
                    ) : (
                      <div className="task--time--box">
                        <input
                          type="number"
                          value={Math.floor(task.taskAmount / 250)}
                          onChange={(e) => {
                            let inputValue = e.target.value;
                            if (
                              inputValue.startsWith('0') &&
                              inputValue.length > 1
                            ) {
                              inputValue = inputValue.substring(1); // '0'을 제거
                            }
                            if (Number(e.target.value) < 0) {
                              return;
                            }
                            const hoursToAmount = Number(e.target.value) * 250;
                            const minutesToAmount =
                              task.taskAmount % 250 > 0 ? 125 : 0;
                            const totalAmount = hoursToAmount + minutesToAmount;
                            const updatedTaskAmount =
                              hoursToAmount + (task.taskAmount % 250);
                            setNewList((prevList) =>
                              prevList.map((subTask) =>
                                subTask.languageSubTaskKey ===
                                task.languageSubTaskKey
                                  ? {
                                      ...subTask,
                                      totalAmount: totalAmount,
                                      taskAmount: updatedTaskAmount
                                    }
                                  : subTask
                              )
                            );
                          }}
                        />
                        시
                        <TPSelectBasic
                          value={task.taskAmount % 250 > 0 ? '0.5' : '0'}
                          options={[
                            {
                              label: '0',
                              option: '0'
                            },
                            {
                              label: '30',
                              option: '0.5'
                            }
                          ]}
                          changeSelect={(e) => {
                            const minutesToAmount =
                              Number(e.target.value) * 250;
                            const hoursToAmount =
                              Math.floor(task.taskAmount / 250) * 250;
                            const totalAmount = hoursToAmount + minutesToAmount;
                            const updatedTaskAmount =
                              hoursToAmount + minutesToAmount;
                            setNewList((prevList) =>
                              prevList.map((subTask) =>
                                subTask.languageSubTaskKey ===
                                task.languageSubTaskKey
                                  ? {
                                      ...subTask,
                                      totalAmount: totalAmount,
                                      taskAmount: updatedTaskAmount
                                    }
                                  : subTask
                              )
                            );
                          }}
                          autoWidth={false}
                        />
                        분
                      </div>
                    )}
                  </div>
                </div>
                <ul className="worker--list">
                  {task.workerList
                    ?.sort((a: Worker, b: Worker) => {
                      const order: WorkerOrder = {
                        TRANSLATOR: 1,
                        FIRST_CHECKER: 2,
                        SECOND_CHECKER: 3
                      };
                      return (
                        order[a.workerType ?? ''] - order[b.workerType ?? '']
                      );
                    })
                    .map((worker: Worker, index: number) => (
                      <li
                        key={index}
                        className={`worker--item ${
                          selectedTaskIndex === taskIndex &&
                          selectedWorkerIndex === index
                            ? 'selected'
                            : ''
                        }`}
                      >
                        <strong className="sub--title">
                          {worker?.workerType === 'TRANSLATOR' && '번역'}
                          {worker?.workerType === 'SECOND_CHECKER' &&
                            '2차 검수'}
                          {worker?.workerType === 'FIRST_CHECKER' && '1차 검수'}
                          {worker?.workerType === 'TRANSLATOR' && !isPM && (
                            <span className="required">*</span>
                          )}
                        </strong>
                        {worker.name === '' ? (
                          <div
                            className={`worker--box ${
                              taskStatus === 'DELIVERY_COMPLETE' && 'disabled'
                            }`}
                            onClick={() => {
                              if (taskStatus === 'DELIVERY_COMPLETE') {
                                return;
                              }
                              clickAssigning(taskIndex, index);
                            }}
                          >
                            <p>배정하기</p>
                            {taskStatus !== 'DELIVERY_COMPLETE' && (
                              <span className="arrow--right">more</span>
                            )}
                          </div>
                        ) : (
                          <div
                            className={`user--box ${
                              worker.workerStatus === 'NEW' && 'workerStatus'
                            }`}
                          >
                            <span
                              className={`img--box ${
                                worker.avatarImgUrl ? '' : 'img--none'
                              }`}
                            >
                              <img
                                src={
                                  worker.avatarImgUrl
                                    ? worker.avatarImgUrl
                                    : IconDefaultProfileUrl
                                }
                                onError={(e) => {
                                  e.currentTarget.src = IconDefaultProfileUrl;
                                }}
                                alt="작업자 프로필"
                              />
                            </span>
                            <div>
                              <div className="worker-outsourcing">
                                <input
                                  type="checkbox"
                                  id={`type${index}-${taskIndex}`}
                                  onChange={() => {
                                    handleOutsourceChange(
                                      worker.workerType ?? '',
                                      taskIndex
                                    );
                                  }}
                                  checked={worker.isOutsourceOrNot || false}
                                />
                                <label htmlFor={`type${index}-${taskIndex}`}>
                                  외주
                                </label>
                              </div>
                              <div className="worker-name">
                                {worker.name}
                                {worker.workerStatus === 'NEW' && (
                                  <>
                                    <span className="arrow">more</span>
                                    <div className="worker-control">
                                      <ul>
                                        <li
                                          onClick={() =>
                                            clickAssigning(taskIndex, index)
                                          }
                                        >
                                          수정하기
                                        </li>
                                        <li
                                          onClick={() =>
                                            clickUnassign(taskIndex, index)
                                          }
                                        >
                                          배정 안함
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    ))}
                </ul>
                <div>
                  <strong className="sub--title">메모</strong>
                  <div className="memo--box">
                    <input
                      type="text"
                      placeholder="30자 이내로 입력해주세요"
                      value={
                        inputValues[task.languageSubTaskKey]
                          ? inputValues[task.languageSubTaskKey]
                          : ''
                      }
                      className={`${
                        newList[taskIndex].memoWarning ? 'warning' : ''
                      }`}
                      onChange={(e) =>
                        handleInputChange(
                          task.languageSubTaskKey,
                          e.target.value
                        )
                      }
                    />
                    <p>
                      <span
                        className={`${
                          newList[taskIndex].memoWarning ? 'warning' : ''
                        }`}
                      >
                        {inputLength[task.languageSubTaskKey]
                          ? inputLength[task.languageSubTaskKey]
                          : '0'}
                      </span>
                      /30
                    </p>
                  </div>
                </div>
              </div>
            )}
            {/* {data.languageSubTaskList[taskIndex] === undefined &&
              task.languageSubTaskKey.slice(-2) !== '01' && (
                <button
                  className="list--close"
                  onClick={removeEventListener(task)}
                >
                  <IconCloseWhite />
                </button>
              )} */}
            {task.isDeletable && newList.length > 1 && (
              <button
                className="list--close"
                onClick={removeEventListener(task)}
              >
                <IconCloseWhite />
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};
