import { ReactElement } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Box, TextField } from '@mui/material';
import { TPInputRadio } from '@src/units/input/TPInput.radio';
import { ThemeProvider } from '@mui/material/styles';
import { TPSelectTheme } from './TPSelect.Theme';
import { IconBulDown } from '@resources/icon';

interface ISelectProps {
  selectId?: string;
  selectType?: 'radio' | 'label';
  label?: string | ReactElement;
  options?: { value?: string; label: string; option: string }[];
  value?: string | string[];
  autoWidth?: boolean;
  multiple?: boolean;
  isDisabled?: boolean;
  large?: boolean;
  placeholder?: string;
  helperText?: string;
  changeSelect?: (event: any) => void;
  color?: string;
}

export const TPSelectBasic = (props: ISelectProps) => {
  const {
    selectId = '',
    selectType = 'label',
    label = '',
    options = [],
    autoWidth = true,
    multiple = false,
    isDisabled = false,
    large = false,
    placeholder,
    helperText,
    changeSelect,
    color
  } = props;
  const { value = multiple ? [] : '' } = props;

  return (
    <ThemeProvider theme={TPSelectTheme}>
      <TextField
        className={large ? 'large' : ''}
        id={selectId}
        value={value}
        InputProps={
          label
            ? {
                startAdornment: <span className={'span--label'}>{label}</span>,
                className: 'test'
              }
            : { className: 'test' }
        }
        onChange={changeSelect}
        SelectProps={{
          IconComponent: (props) => <IconBulDown {...props} />,
          autoWidth: autoWidth,
          multiple: multiple,
          displayEmpty: !!placeholder,
          MenuProps: { classes: { paper: multiple ? 'multiple' : '' } }
        }}
        helperText={helperText}
        select
        disabled={isDisabled}
      >
        {placeholder && (
          <MenuItem value={''}>
            <Box component={'em'} color={color ?? '#9A9CB5'}>
              {placeholder}
            </Box>
          </MenuItem>
        )}
        {options.map((item, index) => {
          return selectType === 'label' ? (
            <MenuItem
              key={index}
              value={item.option}
              style={{ fontFamily: 'IBM Plex Sans KR, sans-serif' }}
            >
              {`${item.label}${item.value ? ' (' + item.value + ')' : ''}`}
            </MenuItem>
          ) : (
            <Box ml={1.5}>
              <TPInputRadio
                required={true}
                name={'category'}
                options={[{ label: item.label, value: item.label }]}
              />
            </Box>
          );
        })}
      </TextField>
    </ThemeProvider>
  );
};
