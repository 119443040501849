import styled from '@emotion/styled';
import { IconInfoBlack } from '@resources/icon';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';
import { useLayoutEffect, useMemo } from 'react';
import { useApi2TaskList } from '../hooks/useApi2TaskList';
import DoneDateFilter, { useDoneDateFilterProps } from './DoneDateFilter';
import FutureDateFilter, { useFutureDateFilterProps } from './FutureDateFilter';
import { Row2TaskItem } from './Row2TaskItem';
import { SortButton } from '@src/pages/components/SortButton';
import { sortByTaskStatus } from '@src/common/config/Code';
import { renderGrossInfo, renderNetInfo } from '../utils/render';
import { cssResetButton } from '../utils/css';

export const FUTURE_TOOLTIP_MESSAGE = `
Future 항목에 해당하는 언어 작업을 조회할 기간을 <br />설정할 수 있습니다.<br /><br />
현재 날짜의 7일 후부터 시작하여 최대 1년 조회 가능하며, <br />
조회해야 할 데이터가 많을 경우, 불러오는 시간이 오래 걸릴 수 있습니다.<br /><br />
더 많은 데이터를 조회하고 싶을 경우, <br /><a style="font-weight:700;color:#9b51e0!important;" href="/task/all">‘전체 작업 목록'</a> 화면에서 확인하실 수 있습니다.`;
export const DONE_TOOLTIP_MESSAGE = `Done 항목에 해당하는 언어 작업을 조회할 기간을<br /> 설정할 수 있습니다.<br /><br />
조회 가능한 기간은 최대 1년이며, 조회해야 할 데이터가<br /> 많을 경우, 불러오는 시간이 오래 걸릴 수 있습니다. <br /><br />
더 많은 데이터를 조회하고 싶을 경우, <br /><a style="font-weight:700;color:#9b51e0!important;" href="/task/all">'전체 작업 목록'</a> 화면에서 확인하실 수 있습니다.`;

export function Row2TaskList({
  filterData,
  item,
  setOpenedJobModifyPage,
  setOpenedJobRegisterPage,
  setOpenedJobDetailPage,
  filterExposure,
  isFilter,
  listFilter,
  sortState,
  onSort
}: any) {
  const sortConfig = useMemo(
    () => ({
      name: '',
      type: undefined as 'up' | 'down' | undefined
    }),
    [item.label]
  );
  const currentSort = sortState[item.label] || { name: '', type: undefined };
  const futureDateFilter = useFutureDateFilterProps();
  const doneDateFilter = useDoneDateFilterProps();
  const dateFilter =
    item.label === 'FUTURE'
      ? {
          dateType: futureDateFilter.dateType,
          startDateTime: futureDateFilter.startDateTime,
          endDateTime: futureDateFilter.endDateTime
        }
      : item.label === 'DONE'
      ? {
          dateType: doneDateFilter.dateType,
          startDateTime: doneDateFilter.startDateTime,
          endDateTime: doneDateFilter.endDateTime
        }
      : {};

  const { data, error, isSuccess, isError } = useApi2TaskList(item.label, {
    ...filterData,
    ...dateFilter
  }); // taskData

  const sortedData =
    currentSort.type && Array.isArray(data) && data.length > 0
      ? [...data]?.sort((a: any, b: any) => {
          const ak = a[currentSort.name];
          const bk = b[currentSort.name];
          if (ak !== undefined || bk !== undefined) {
            if (currentSort.name === 'taskStatus') {
              return sortByTaskStatus(a, b, currentSort.type === 'up');
            } else {
              if (currentSort.type === 'up') {
                if (ak < bk) return -1;
                if (ak > bk) return 1;
              } else {
                if (ak > bk) return -1;
                if (ak < bk) return 1;
              }
            }
          }
          return 0;
        })
      : data;

  useLayoutEffect(() => {
    const cn = `.${item.label}.items`;
    const el = document.querySelector(cn) as any;
    if ('textContent' in el) {
      el.textContent = `${data?.length ?? 0}`;
    }
  }, [data]);

  if (!isSuccess) return <></>;

  return (
    <StyledRow2TaskList className="deeps-2">
      {item.label === 'FUTURE' && (
        <div className="filter-toolbar">
          <div className="title">
            <strong>조회기간</strong>
            <SimpleTooltip message={FUTURE_TOOLTIP_MESSAGE} position="top">
              <IconInfoBlack width={16} height={16} />
            </SimpleTooltip>
          </div>
          <FutureDateFilter
            {...futureDateFilter}
            disabledHopeSupplyDate={false}
          />
        </div>
      )}
      {item.label === 'DONE' && (
        <div className="filter-toolbar">
          <div className="title">
            <strong>조회기간</strong>
            <SimpleTooltip message={DONE_TOOLTIP_MESSAGE} position="top">
              <IconInfoBlack width={16} height={16} />
            </SimpleTooltip>
          </div>
          <DoneDateFilter {...doneDateFilter} disabledHopeSupplyDate={false} />
        </div>
      )}
      <table>
        <thead>
          <tr>
            <th style={{ width: 150 }}>
              <SortButton
                show={
                  currentSort.name === 'taskStatus' && Boolean(currentSort.type)
                }
                sortType={
                  currentSort.name === 'taskStatus'
                    ? currentSort.type
                    : undefined
                }
                onChange={(v) => {
                  onSort(item.label, v ? 'taskStatus' : '', v);
                }}
              >
                상태
              </SortButton>
            </th>
            <th style={{ minWidth: 172, maxWidth: 360 }}>작업</th>
            <th style={{ minWidth: 122, maxWidth: 235 }}>고객사</th>
            <th style={{ minWidth: 134, maxWidth: 475 }}>프로젝트</th>
            <th style={{ width: 100 }}>유형</th>
            <th style={{ width: 110 }}>출발어</th>
            <th style={{ width: 110 }}>도착어</th>
            <th style={{ width: 120 }}>작업자</th>
            <th style={{ width: 110 }}>
              <SortButton
                show={
                  currentSort.name === 'startDateTime' &&
                  Boolean(currentSort.type)
                }
                sortType={
                  currentSort.name === 'startDateTime'
                    ? currentSort.type
                    : undefined
                }
                onChange={(v) => {
                  onSort(item.label, v ? 'startDateTime' : '', v);
                }}
              >
                시작일
              </SortButton>
            </th>
            <th style={{ width: 110 }}>
              <SortButton
                show={
                  currentSort.name === 'endDateTime' &&
                  Boolean(currentSort.type)
                }
                sortType={
                  currentSort.name === 'endDateTime'
                    ? currentSort.type
                    : undefined
                }
                onChange={(v) => {
                  onSort(item.label, v ? 'endDateTime' : '', v);
                }}
              >
                마감일
              </SortButton>
            </th>
            <th style={{ width: 120 }}>{renderGrossInfo()}</th>
            <th style={{ width: 120 }}>{renderNetInfo()}</th>
            <th style={{ width: 110 }}>
              <SortButton
                show={
                  currentSort.name === 'hopeSupplyDateTime' &&
                  Boolean(currentSort.type)
                }
                sortType={
                  currentSort.name === 'hopeSupplyDateTime'
                    ? currentSort.type
                    : undefined
                }
                onChange={(v) => {
                  onSort(item.label, v ? 'hopeSupplyDateTime' : '', v);
                }}
              >
                납품일
              </SortButton>
            </th>
            <th style={{ width: 80 }}>담당 PM</th>
            <th style={{ width: 70 }}></th>
          </tr>
        </thead>
        {Array.isArray(sortedData) && sortedData.length > 0 ? (
          sortedData.map((d: any, i: number) => (
            <Row2TaskItem
              key={d.taskId}
              {...{
                label: item.label,
                filterData,
                item: d,
                setOpenedJobModifyPage,
                setOpenedJobRegisterPage,
                setOpenedJobDetailPage,
                filterExposure,
                isFilter
              }}
            />
          ))
        ) : (
          <tbody>
            <tr>
              <td
                colSpan={15}
                style={{
                  height: 48,
                  borderBottom: 'none'
                }}
              >
                {listFilter
                  ? '일치하는 작업이 없습니다.'
                  : '등록된 작업이 없습니다.'}
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </StyledRow2TaskList>
  );
}

const StyledRow2TaskList = styled.div`
  width: 1840px;
  margin-top: 8px;
  margin-bottom: 48px;

  .filter-toolbar {
    position: relative;
    z-index: 1052;
    display: inline-flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin-top: 14px;

    .title {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    & > *:first-of-type {
      transform: translate(2px, -6px);
    }

    .main--button--refresh {
      margin-bottom: 16px;
      border: 1px solid #cbcbcb;
      padding: 5px 14px;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: -0.25px;
      text-align: left;
      color: #242424;

      &:hover {
        color: #9b51e0;
      }
    }
  }

  table {
    width: 100%;

    border-collapse: collapse;
    border: 1px solid #ececec;
    /* table-layout: fixed; */

    thead {
      tr {
        border-bottom: 1px solid #ececec;
        th {
          position: relative;
          font-weight: 500;
          color: #2c3558;
          height: 34px;
          white-space: nowrap;
          text-align: center;
          vertical-align: middle;
          font-size: 14px;
          background-color: #fff;

          &:first-of-type {
            border-radius: 2px 0 0 0;
          }
          &:last-of-type {
            border-radius: 0 2px 0 0;
          }

          svg {
            width: 16px;
            height: 16px;
            display: inline;
          }
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #ececec;
        &:first-of-type {
          background-color: #fff;
        }

        &:hover {
          td {
            background-color: #f8f5fc;
          }
        }

        td {
          margin: 0;
          padding: 0;
          white-space: nowrap;
          /* padding: 7px 10px; */
          /* min-height: 36px; */
          text-align: center;
          vertical-align: middle;
          font-size: 14px;
          line-height: 160%;
          border: none;
          outline: none;
          color: #727272;
        }
      }
    }

    .pm-list {
      white-space: nowrap;
      & > * {
        width: auto;
        display: inline-flex;
      }
      .language-task {
        background-color: red;
      }
    }
  }
  .tooltipWrap {
    a {
      color: #9b51e0;
    }
  }
`;
