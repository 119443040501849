import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { QueryFunctionContext } from '@tanstack/query-core';
import _ from 'lodash';
import api from '@api/Api';
import { useHookFunc, usePageFunc } from '@hooks/utils';
import { myWorkKeys } from '@hooks/queryKeyFactory';
import { ILanguageTask } from '@src/common/config/IProjectDetail';
import { IMyWorkList } from '@src/common/config/IMyTask';
import { WidgetCode } from '@src/common/config/Code';

/**
 * /myWork/list
 * @description 내 작업 페이지 목록 조회
 * @returns {{
 *  isLoading, isError, subTaskListObj, subTaskFilter,
 *  dashBoardItemList, dateType, dateFilter, setState
 * }}
 */
export const usePostMyWorkTabList = (filterData: any) => {
  const { arraySet } = useHookFunc();
  const { getDateTime } = usePageFunc();

  //#region state define
  const [languagePairList, setLanguagePairList] = useState<ILanguageTask[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [taskTitle, setTaskTitle] = useState<string>('');
  const [myWorkFilter, setMyWorkFilter] = useState<{ [key: string]: any[] }>({
    projectManagerUserIdList: ['0'],
    taskType: ['ALL'],
    workerType: ['ALL'],
    workerStatus: ['NEW', 'PROGRESS', 'WAIT_QUERY', 'REJECT']
  });
  const [dateType, setDateType] = useState<string>('ALL');
  const [dateFilter, setDateFilter] = useState<{
    [key: string]: Date | undefined;
  }>({
    startDateTime: undefined,
    endDateTime: undefined
  });
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [isSort, setIsSort] = useState<{ [key: string]: any }>({
    code: '',
    isAsc: false
  });
  //#endregion

  //#region setState
  const setState = {
    setTaskType: (e: any) => {
      const taskType =
        e === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(myWorkFilter.taskType, e),
              (item) => item !== 'ALL'
            );
      setMyWorkFilter({
        ...myWorkFilter,
        taskType: taskType.length ? taskType : ['ALL']
      });
    },
    setWorkerType: (e: any) => {
      const workerType =
        e === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(myWorkFilter.workerType, e),
              (item) => item !== 'ALL'
            );
      setMyWorkFilter({
        ...myWorkFilter,
        workerType: workerType.length ? workerType : ['ALL']
      });
    },
    setWorkStatus: (e: any) => {
      const workerStatus =
        e === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(myWorkFilter.workerStatus, e),
              (item) => item !== 'ALL'
            );
      setMyWorkFilter({
        ...myWorkFilter,
        workerStatus: workerStatus.length ? workerStatus : ['ALL']
      });
    },
    setPmManager: (e: any) => {
      console.debug('e', e);
      const projectManagerUserIdList =
        e === '0'
          ? ['0']
          : _.filter(
              arraySet(myWorkFilter.projectManagerUserIdList, e),
              (item) => item !== '0'
            );
      setMyWorkFilter({
        ...myWorkFilter,
        projectManagerUserIdList: projectManagerUserIdList.length
          ? projectManagerUserIdList
          : ['0']
      });
    },
    setTaskTitle,
    setDateType,
    setDateFilter,
    setKeyword,
    setLanguagePairList,
    setResetState: () => {
      setLanguagePairList([]);
      setKeyword('');
      setTaskTitle('');
      setMyWorkFilter({
        projectManagerUserIdList: ['0'],
        taskType: ['ALL'],
        workerType: ['ALL'],
        workerStatus: ['NEW', 'PROGRESS', 'WAIT_QUERY', 'REJECT']
      });
      setDateType('ALL');
      setDateFilter({ startDateTime: undefined, endDateTime: undefined });
    },
    setIsSort
  };
  //#endregion

  //#region payload set
  const setPayload = ({
    keyword,
    languagePairList,
    taskTitle,
    myWorkFilter,
    dateType,
    dateFilter
  }: {
    languagePairList: ILanguageTask[];
    taskTitle: string;
    keyword: string;
    myWorkFilter: { [key: string]: any[] };
    dateType: string;
    dateFilter: { [key: string]: any };
  }): object => {
    const { startDateTime, endDateTime } = dateFilter;
    const taskFilterArr: { [key: string]: any[] } = {};
    const getLanguagePairList =
      !_.some(languagePairList, { startLanguage: 'ALL' }) &&
      languagePairList.length
        ? { languagePairList }
        : undefined;

    Object.keys(myWorkFilter).forEach((key) => {
      _.map(
        myWorkFilter[key],
        (el) =>
          !['ALL', '0'].includes(el) && (taskFilterArr[key] = myWorkFilter[key])
      );
    });

    return {
      ...(getLanguagePairList && getLanguagePairList),
      ...(taskTitle && { taskTitle }),
      ...(keyword && { keyword }),
      ...taskFilterArr,
      ...(dateType !== 'ALL' &&
        startDateTime &&
        endDateTime && {
          dateType,
          startDateTime: getDateTime({
            date: new Date(startDateTime),
            filter: 'start'
          }),
          endDateTime: getDateTime({
            date: new Date(endDateTime),
            filter: 'end'
          })
        })
    };
  };
  //#endregion

  //#region api call
  const getData = async ({
    queryKey
  }: QueryFunctionContext<ReturnType<(typeof myWorkKeys)['list']>>): Promise<
    IMyWorkList[]
  > => {
    const [, , filters] = queryKey;
    const getPayload = setPayload(filters as any); // TODO: TYPE ERROR

    setIsFilter(
      !(
        Object.keys(getPayload).length === 1 &&
        'workerStatus' in getPayload &&
        Array.isArray(getPayload.workerStatus) &&
        getPayload.workerStatus.length === 4 &&
        getPayload.workerStatus.includes('NEW') &&
        getPayload.workerStatus.includes('PROGRESS') &&
        getPayload.workerStatus.includes('WAIT_QUERY') &&
        getPayload.workerStatus.includes('REJECT')
      )
    );

    return await api
      .post('/myWork/list', { ...getPayload })
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const {
    isLoading,
    isError,
    data: myWorkList = []
  } = useQuery(
    [
      ...myWorkKeys.list({
        languagePairList,
        taskTitle,
        myWorkFilter,
        dateType,
        dateFilter
      })
    ],
    getData,
    {
      enabled: false, // 사실상 사용하지 않는다.
      select: (data) =>
        isSort.code.length
          ? ([
              ..._.orderBy(data, [isSort.code], [isSort.isAsc ? 'asc' : 'desc'])
            ] as IMyWorkList[])
          : data
    }
  );
  //#endregion

  //#region 상단 count
  const dashBoardItemList = WidgetCode.widgetMyWork.map((code) => ({
    ...code,
    count: Number(
      _.size(_.filter(myWorkList, (v: any) => v.workerStatus === code.icon))
    )
  }));

  //#endregion
  const oldFilterDataRef = useRef<any>();
  useEffect(() => {
    if (_.isEqual(oldFilterDataRef.current, filterData)) return;
    // console.debug('filterData', oldFilterDataRef.current, '->', filterData);
    oldFilterDataRef.current = filterData;

    setLanguagePairList((v) => filterData?.languagePairList ?? v ?? []);
    setTaskTitle((v) => filterData?.taskTitle ?? v ?? '');
    setKeyword((v) => filterData?.keyword ?? v ?? '');
    setMyWorkFilter(
      (v) =>
        filterData?.myWorkFilter ??
        v ?? {
          projectManagerUserIdList: ['0'],
          taskType: ['ALL'],
          workerType: ['ALL'],
          workerStatus: ['NEW', 'PROGRESS', 'WAIT_QUERY', 'REJECT']
        }
    );
    setDateType((v) => filterData?.dateType ?? v ?? 'ALL');
    setDateFilter(
      (v) =>
        filterData?.dateFilter ??
        v ?? {
          startDateTime: undefined,
          endDateTime: undefined
        }
    );
  }, [filterData]);

  return {
    isLoading,
    isError,
    languagePairList,
    myWorkList,
    dashBoardItemList,
    taskTitle,
    myWorkFilter,
    dateType,
    dateFilter,
    isFilter,
    isSort,
    keyword,
    setKeyword,
    setState
  };
};
